import React from "react";
import styles from "./PartnerSection.module.scss";
import { v4 as uuidv4 } from "uuid";
import { TPartnerProps } from "../../@types/type";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { REACT_APP_AMAZON_S3_URL } from "../../constants/constant";
import { InfiniteLooper } from "../../components/InfiniteLooper/InfiniteLooper";

const PartnerSection = () => {
  const { t } = useTranslation();
  const { partnerData } = useSelector((state: RootState) => state.main);

  return (
    <div className={styles.partnerSection}>
      <div className={`container ${styles.partnerSectionBody}`}>
        <div className={styles.partnerSectionTitle}>
          {t("home_page.partner.title_text")} <span>SPA</span>
        </div>
        <p className={styles.description}>
          {t("home_page.partner.description_text")}
        </p>
        {partnerData?.length && (
          <InfiniteLooper direction="left" speed={6}>
            {partnerData?.map((item: TPartnerProps, index: number) => {
              return (
                <div className={styles.contentBlock} key={uuidv4()}>
                  <Link to={item.website} target="_blank">
                    <img
                      src={`${REACT_APP_AMAZON_S3_URL}/${item.logo}`}
                      alt="content_img"
                    />
                  </Link>
                </div>
              );
            })}
          </InfiniteLooper>
        )}
      </div>
    </div>
  );
};

export default PartnerSection;
