import React from "react";
import styles from "./HeroContent.module.scss";
import { TSlideProps } from "../../../@types/type";
import { useTranslation } from "react-i18next";
import { REACT_APP_AMAZON_S3_URL } from "../../../constants/constant";
import Button from "../../../components/Button/Button";
import useScreenSize from "../../../hooks/useScreenSize";

const HeroContent: React.FC<TSlideProps> = (props) => {
  const { i18n } = useTranslation();
  const { width } = useScreenSize();

  return (
    <div className={styles.heroSectionContent}>
      <div className={styles.contentInfo}>
        <div className={styles.slider} />
        <h3 className={styles.title}>
          {props[`title_${i18n.language}` as keyof TSlideProps]}
        </h3>
        <p className={styles.subTitle}>
          {props[`subtitle_${i18n.language}` as keyof TSlideProps]}
        </p>
        <div className={styles.action}>
          <Button
            variant="contained"
            color="dark"
            size={width < 600 ? "small" : "medium"}
            onClick={() => window.open(`${props.button_url}`, "_blank")}
          >
            {props[`button_title_${i18n.language}` as keyof TSlideProps]}
          </Button>
        </div>
      </div>
      <div className={styles.contentImage}>
        <img
          src={`${REACT_APP_AMAZON_S3_URL}/${props.image_url}`}
          alt="content_img"
        />
      </div>
    </div>
  );
};

export default HeroContent;
