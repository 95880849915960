import {
  ActionReducerMapBuilder,
  PayloadAction,
  createSlice,
  current,
} from "@reduxjs/toolkit";
import {
  TCardDetailsProps,
  TCompanyDetailsFormProps,
  TContactDetailsFormProps,
  TPersonalDetailsFormProps,
} from "../../@types/type";
import { DateFormatToOneString, getDate } from "../../utils/formats";
import {
  updatePhoneNumber,
  confirmEmail,
  confirmVerificationCode,
  getMemberById,
  registerUser,
  resendVerificationLink,
  verifyUserEmail
} from "../api";

type TJoinUsState = {
  personalDetails: TPersonalDetailsFormProps;
  contactDetails: TContactDetailsFormProps;
  companyDetails: TCompanyDetailsFormProps;
  cardDetails: TCardDetailsProps;
  isError: boolean;
  isLoading: boolean;
  message: string;
  isEmailVerified: boolean;
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  isLinkTokenExpired: boolean;
  isEmailSuccessfullyVerified: boolean;
  isLinkSent: boolean;
  isCodeSuccessfullyVerified: boolean;
  otpCode: string;
};

const initialState: TJoinUsState = {
  personalDetails: {
    firstName: "",
    lastName: "",
    nationality: "",
    residency: "",
    date: "",
    gender: "",
    dateValue: {
      day: "",
      year: "",
    },
    selectedMonth: {
      label: "",
      value: "",
    },
    selectedNationality: {
      label: "",
      value: "",
    },
    selectedResidency: {
      label: "",
      value: "",
    },
  },
  companyDetails: {
    companyName: "",
    country: "",
    jobTitle: "",
    security: "",
    contribute: "",
    licenseCountry: "",
    licenseIDNumber: "",
    multipleContribution: [],
    selectedFile: null,
    selectedLicenseCountry: {
      label: "",
      value: "",
    },
    selectedSecurity: {
      label: "",
      value: "",
    },
  },
  contactDetails: {
    phone: "",
    email: "",
    securityIndustry: true,
    verifyPhone: false,
  },
  cardDetails: {
    emiratesIdNumber: "",
    emiratesIdExpiredDate: "",
    martialStatus: "Single"
  },
  isError: false,
  isPhoneConfirmed: false,
  isLoading: false,
  isEmailVerified: false,
  isEmailConfirmed: false,
  isLinkTokenExpired: false,
  isEmailSuccessfullyVerified: false,
  isLinkSent: false,
  isCodeSuccessfullyVerified: false,
  otpCode: "",
  message: "",
};

const joinUsSlice = createSlice({
  name: "join_us",
  initialState,
  reducers: {
    // personal details
    setPersonalDetails: (
      state: TJoinUsState,
      action: PayloadAction<TCompanyDetailsFormProps | any>
    ) => {
      const { key, value } = action.payload;
      if (key === "year" || key === "day") {
        state.personalDetails = {
          ...state.personalDetails,
          dateValue: { ...state.personalDetails.dateValue, [key]: value },
        };
      } else if (key === "gender") {
        state.personalDetails = { ...state.personalDetails, gender: value };
      } else {
        state.personalDetails = { ...state.personalDetails, [key]: value };
      }

      if (
        state.personalDetails.dateValue &&
        state.personalDetails.selectedMonth
      ) {
        const date = DateFormatToOneString(
          state.personalDetails.dateValue,
          state.personalDetails.selectedMonth
        );
        state.personalDetails = { ...state.personalDetails, date };
      }

      if (state.personalDetails.selectedNationality) {
        state.personalDetails = {
          ...state.personalDetails,
          nationality: state.personalDetails.selectedNationality.value,
        };
      }

      if (state.personalDetails.selectedResidency) {
        state.personalDetails = {
          ...state.personalDetails,
          residency: state.personalDetails.selectedResidency.label,
        };
      }
    },
    // company details
    setCompanyDetails: (
      state: TJoinUsState,
      action: PayloadAction<TContactDetailsFormProps | any>
    ) => {
      const { key, value } = action.payload;

      if (key !== "multipleContribution") {
        state.companyDetails = { ...state.companyDetails, [key]: value };
      }

      if (key === "selectedSecurity") {
        state.companyDetails = {
          ...state.companyDetails,
          security: value ? value.label : null,
        };
      } else if (key === "selectedLicenseCountry") {
        state.companyDetails = {
          ...state.companyDetails,
          licenseCountry: value ? value.label : null,
        };
      } else if (key === "file") {
        state.companyDetails = { ...state.companyDetails, selectedFile: value };
      } else if (key === "contribute") {
        const multipleContributionArray = current(
          state.companyDetails.multipleContribution
        );
        state.companyDetails = { ...state.companyDetails, contribute: value };
        if (multipleContributionArray.length > 0 && value === "No") {
          state.companyDetails.multipleContribution = [];
        }
      } else if (key === "multipleContribution") {
        let cloneCompanyDetails = [
          ...state.companyDetails.multipleContribution,
        ];
        if (cloneCompanyDetails.includes(value)) {
          cloneCompanyDetails.splice(cloneCompanyDetails.indexOf(value), 1);
          state.companyDetails = {
            ...state.companyDetails,
            multipleContribution: cloneCompanyDetails,
          };
        } else {
          cloneCompanyDetails.push(value);
          state.companyDetails = {
            ...state.companyDetails,
            multipleContribution: cloneCompanyDetails,
          };
        }
      }
    },
    //card details 
    setCardDetails: (
      state,
      action: PayloadAction<TContactDetailsFormProps | any>
    ) => {
      const { id, value } = action.payload;
      state.cardDetails = { ...state.cardDetails, [id]: value };
    },
    // contact details
    setContatcDetails: (
      state,
      action: PayloadAction<TContactDetailsFormProps | any>
    ) => {
      const { key, value, security, verifyPhone } = action.payload;
      if (key === "security") {
        state.contactDetails = {
          ...state.contactDetails,
          securityIndustry: security,
        };
      } else if (key === "verifyPhone") {
        state.contactDetails = {
          ...state.contactDetails,
          verifyPhone,
        };
      } else {
        state.contactDetails = { ...state.contactDetails, [key]: value };
      }
    },
    // error
    setError: (state, action) => {
      state.isError = action.payload;
    },
    // clear state
    clearJoinUsState: (state: TJoinUsState) => {
      state.personalDetails = {
        firstName: "",
        lastName: "",
        nationality: "",
        residency: "",
        date: "",
        gender: "",
        dateValue: {
          day: "",
          year: "",
        },
        selectedMonth: {
          label: "",
          value: "",
        },
        selectedNationality: {
          label: "",
          value: "",
        },
        selectedResidency: {
          label: "",
          value: "",
        },
      };
      state.companyDetails = {
        companyName: "",
        country: "",
        jobTitle: "",
        security: "",
        contribute: "",
        licenseCountry: "",
        licenseIDNumber: "",
        selectedFile: null,
        multipleContribution: [],
        selectedLicenseCountry: {
          label: "",
          value: "",
        },
        selectedSecurity: {
          label: "",
          value: "",
        },
      };
      state.contactDetails = {
        phone: "",
        email: "",
        securityIndustry: true,
        verifyPhone: false,
      };
      state.cardDetails = {
        emiratesIdNumber: "",
        emiratesIdExpiredDate: "",
        martialStatus: "Single"
      }
    },
    //otp verification code
    setOTPCode: (
      state: TJoinUsState,
      action: PayloadAction<TContactDetailsFormProps | any>
    ) => {
      state.otpCode = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(registerUser.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        registerUser.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const { data } = action.payload.data;
          if (data) {
            console.log("dataaaaaaaa", data);
          }
          state.isLoading = false;
        }
      )
      .addCase(registerUser.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder.addCase(verifyUserEmail.fulfilled, (state: TJoinUsState) => {
      state.isEmailVerified = true;
    }).addCase(verifyUserEmail.rejected, (state: TJoinUsState) => {
      state.isEmailVerified = false;
    })
    builder.addCase(
      getMemberById.fulfilled,
      (state: TJoinUsState, action: PayloadAction<any>) => {
        const { data } = action.payload?.data;
        if (data) {
          const date = getDate(data.birthdate);
          state.personalDetails = {
            firstName: data.firstName,
            lastName: data.lastName,
            nationality: data.nationality,
            residency: data.residency,
            date: data.birthdate,
            gender: data.gender,
            dateValue: {
              day: date.day,
              year: date.year,
            },
            selectedMonth: {
              label: date.month.label ? date?.month.label : "",
              value: date.month.value ? date?.month.value : "",
            },
            selectedNationality: {
              label: data.nationality,
              value: data.nationality,
            },
            selectedResidency: {
              label: data.residency,
              value: data.residency,
            },
          };
          state.companyDetails = {
            companyName: data.companyName,
            country: data.country,
            jobTitle: data.jobTitle,
            security: data.security,
            contribute: data.contribution?.length > 0 ? "Yes" : "No",
            licenseCountry: data.licenseIssuingCountry,
            licenseIDNumber: data.licenseIdNumber,
            selectedFile: data.licenseFile || '',
            multipleContribution: data.contribution || [],
            selectedLicenseCountry: {
              label: data.licenseIssuingCountry,
              value: data.licenseIssuingCountry,
            },
            selectedSecurity: {
              label: data.security,
              value: data.security,
            },
          };
          state.contactDetails = {
            phone: data.phone,
            email: data.email,
            securityIndustry: true,
            verifyPhone: false,
          };
          state.isPhoneConfirmed = data.isPhoneConfirmed
            ? data.isPhoneConfirmed
            : false;
        }
      }
    );
    builder
      .addCase(confirmEmail.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        confirmEmail.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;

          if (data.isEmailConfirmed) {
            state.isEmailConfirmed = data.isEmailConfirmed;
          } else if (data.isLinkTokenExpired) {
            state.isLinkTokenExpired = data.isLinkTokenExpired;
          } else if (data.isEmailSuccessfullyVerified) {
            state.isEmailSuccessfullyVerified =
              data.isEmailSuccessfullyVerified;
          }

          state.isLoading = false;
        }
      )
      .addCase(confirmEmail.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(confirmVerificationCode.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        confirmVerificationCode.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;

          state.isLoading = false;
          state.isCodeSuccessfullyVerified = data.isCodeVerified;
        }
      )
      .addCase(confirmVerificationCode.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(resendVerificationLink.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        resendVerificationLink.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const data = action.payload?.data;
          if (data.isLinkSent) {
            state.isLinkSent = data.isLinkSent;
            state.isLinkTokenExpired = false;
          }
          state.isLoading = false;
        }
      )
      .addCase(resendVerificationLink.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
    builder
      .addCase(updatePhoneNumber.pending, (state: TJoinUsState) => {
        state.isLoading = true;
      })
      .addCase(
        updatePhoneNumber.fulfilled,
        (state: TJoinUsState, action: PayloadAction<any>) => {
          const { phone } = action?.payload.data.data.data;
          state.contactDetails.phone = phone;
          state.isLoading = false;
        }
      )
      .addCase(updatePhoneNumber.rejected, (state: TJoinUsState) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  setPersonalDetails,
  setCompanyDetails,
  setContatcDetails,
  setCardDetails,
  clearJoinUsState,
  setError,
  setOTPCode,
} = joinUsSlice.actions;

export default joinUsSlice.reducer;
