import React from "react";
import styles from "./LatestEvents.module.scss";
import HrefIcon from "../../components/icons/HrefIcon";
import { TMediaProps } from "../../@types/type";
import EventCard from "../../components/EventCard/EventCard";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../../app/features/tabSlice";
import { MediaType } from "../../enums/enum";
import { RootState } from "../../app/store";
import { clearMedia } from "../../app/features/mediaSlice";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";

const LatestEvents = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { events } = useSelector((state: RootState) => state.media);

  const handleEventsViewClick = () => {
    dispatch(setSelectedTab(MediaType.EVENT));
    dispatch(clearMedia(MediaType.EVENT));
    dispatch(
      setBreadcrumb({
        url: "/media",
        name: t("media_text"),
        key: "links.media_text",
      })
    );
    navigate("media");
  };

  return (
    <div className={styles.latestEvents}>
      <div className={`container ${styles.latestEventsBody}`}>
        <h3 className={styles.latestEventsTitle}>
          {t("latest_title_text")}{" "}
          <span>{t("home_page.events.title_text")}</span>
        </h3>
        <div className={styles.eventCards}>
          {events.map((item: TMediaProps) => {
            return <EventCard key={uuidv4()} {...item} isBreadcrumb />;
          })}
        </div>
        <div onClick={handleEventsViewClick} className={styles.viewAll}>
          {i18n.dir() === "rtl" && (
            <HrefIcon width="12px" height="13px" fill="#EC1C2B" />
          )}
          <span>{t("view_all_text")}</span>
          {i18n.dir() === "ltr" && (
            <HrefIcon width="12px" height="13px" fill="#EC1C2B" />
          )}
        </div>
      </div>
    </div>
  );
};

export default LatestEvents;
