import React, { useEffect } from "react";
import {
  TBreadcrumbItemState,
  TMenuDataProps,
  TSidebarProps,
} from "../../@types/type";
import styles from "./Sidebar.module.scss";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { Link, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { useTranslation } from "react-i18next";

const Sidebar = ({ isOpen, setOpenSidebar, menuItems }: TSidebarProps) => {
  const dispatch = useDispatch();
  const loaction = useLocation();
  const { t } = useTranslation();
  const links = t("links", { returnObjects: true });

  const ref = useOutsideClick(() => {
    setOpenSidebar(false);
  });

  const handleSidebarBreadcrumbClick = (item: TMenuDataProps) => {
    let obj: TBreadcrumbItemState = { url: item.path, name: item.title };
    Object.values(links).forEach((value, index) => {
      if (item.title === value) {
        obj.key = `links.${Object.keys(links)[index]}`;
      }
    });

    dispatch(setBreadcrumb(obj));
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  useEffect(() => {
    setOpenSidebar(false);
  }, [loaction.pathname, setOpenSidebar]);

  return (
    <div
      className={`${styles.sidebarOverlay} ${
        isOpen ? styles.opened : styles.closed
      }`}
    >
      <div
        ref={ref}
        className={`${styles.sidebar} ${
          isOpen ? styles.opened : styles.closed
        }`}
      >
        <div className={styles.sidebarContent}>
          {menuItems?.map((item: TMenuDataProps) => {
            return (
              <Link
                key={uuidv4()}
                to={item.path}
                onClick={() => handleSidebarBreadcrumbClick(item)}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
