import React, { Dispatch, useEffect, useState } from "react";
import styles from "../JoinUs.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../components/InputField/InputField";
import { RootState } from "../../../app/store";
import {
  setContatcDetails,
  setError,
  setPersonalDetails,
} from "../../../app/features/joinUsSlice";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { TContactDetailsFormProps } from "../../../@types/type";
import RadioButton from "../../../components/RadioButton/RadioButton";

type TConatactDetailsProps = {
  setIsCardsValid: Dispatch<React.SetStateAction<boolean>>;
};

const ContactDetailsCard: React.FC<TConatactDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsCardsValid } = props;
  const { contactDetails, personalDetails, isPhoneConfirmed } = useSelector(
    (state: RootState) => state.joinUs
  );
  const [contactErrors, setContactErrors] = useState<
    Partial<TContactDetailsFormProps>
  >({
    phone: "",
    email: "",
  });

  const handleContactInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    // Validate email
    if (id === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = value.match(emailRegex);
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        email: isValidEmail ? "" : t("join_us.errors.email_text"),
      }));
    }

    if (id === "notConfirm" || id === "confirm") {
      dispatch(
        setContatcDetails({
          key: "security",
          security: !contactDetails.securityIndustry,
        })
      );
    } else if (id === "notVerify" || id === "verify") {
      dispatch(
        setContatcDetails({
          key: "verifyPhone",
          verifyPhone: !contactDetails.verifyPhone,
        })
      );
    } else if (id === "email") {
      dispatch(setContatcDetails({ key: id, value }));
    } else {
      dispatch(setPersonalDetails({ key: id, value }));
    }
  };

  const validatePhoneNumber = (
    phoneNumber: string,
    countryCode: CountryCode
  ): boolean => {
    try {
      const parsedNumber = parsePhoneNumber(`+${phoneNumber}`, countryCode);
      return parsedNumber.isValid();
    } catch (error: any) {
      return false;
    }
  };

  const handlePhoneChange = (phone: string, country: CountryData) => {
    dispatch(setContatcDetails({ key: "phone", value: phone }));
    const countryCode: any = country.countryCode as CountryCode;
    const isValid = validatePhoneNumber(phone, countryCode.toUpperCase());

    if (!isValid) {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "Invalid phone number",
      }));
    } else {
      setContactErrors((prevErrors) => ({
        ...prevErrors,
        phone: "",
      }));
    }
  };

  useEffect(() => {
    const hasAnyError = Object.values(contactErrors).every((value) => {
      return !value;
    });

    const isFormEmpty = Object.values(contactDetails).every((value) => {
      if (typeof value !== "boolean") {
        return value;
      }
      return true;
    });

    if (!isFormEmpty) {
      dispatch(setError(hasAnyError));
    } else {
      dispatch(setError(hasAnyError));
    }

    const isFormValid = isFormEmpty && hasAnyError;

    setIsCardsValid(isFormValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactErrors, contactDetails]);

  return (
    <div>
      <div className={styles.constactDetails}>
        <p className={styles.sectionTitle}>
          {t("join_us.contact_details_text")}
        </p>
        <div className={styles.sectionContent}>
          <div className={styles.inputField}>
            <InputField
              id="firstName"
              value={personalDetails?.firstName}
              onChange={handleContactInputChange}
              type="string"
              label={t("join_us.first_name.label_text")}
              required
              placeholder={t("join_us.first_name.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="lastName"
              value={personalDetails?.lastName!}
              onChange={handleContactInputChange}
              type="string"
              label={t("join_us.last_name.label_text")}
              required
              placeholder={t("join_us.last_name.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="email"
              value={contactDetails.email}
              onChange={handleContactInputChange}
              type="email"
              label={t("join_us.email.label_text")}
              required
              placeholder={t("join_us.email.placeholder_text")}
            />
            {contactErrors.email && (
              <div className={styles.error}>{contactErrors.email}</div>
            )}
          </div>
          <div className={styles.inputField}>
            <div className={styles.checkboxTitle}>
              <span>{t("join_us.phone.label_text")}</span>
              <span className={styles.required}>*</span>
            </div>
            <PhoneInput
              country={"ae"}
              placeholder={t("join_us.phone.placeholder_text")}
              enableSearch={true}
              value={contactDetails.phone}
              onChange={(phone: string, country: CountryData) =>
                handlePhoneChange(phone, country)
              }
            />
            {contactErrors.phone && (
              <div className={styles.error}>{contactErrors.phone}</div>
            )}
          </div>
          <div className={`${styles.inputField} ${styles.fullWidth}`}>
            <div className={styles.checkboxTitle}>
              <span>{t("join_us.workshop.title_text")}</span>
              <span className={styles.required}>*</span>
            </div>
            <div className={styles.checkboxField}>
              <RadioButton
                id="confirm"
                label={t("join_us.workshop.label_yes_text")}
                value="Yes"
                name="security"
                checked={contactDetails.securityIndustry}
                onChange={handleContactInputChange}
              />
              <RadioButton
                id="notConfirm"
                label={t("join_us.workshop.label_no_text")}
                value="No"
                name="security"
                checked={!contactDetails.securityIndustry}
                onChange={handleContactInputChange}
              />
            </div>
          </div>
          {!isPhoneConfirmed ? (
            <div className={`${styles.inputField} ${styles.fullWidth}`}>
              <div className={styles.checkboxTitle}>
                <span>{t("join_us.verification.verifyPhone")}</span>
                <span className={styles.required}>*</span>
              </div>
              <div className={styles.checkboxField}>
                <RadioButton
                  id="verify"
                  label={t("join_us.verification.label_yes_text")}
                  value="Yes"
                  name="verifyPhone"
                  checked={contactDetails.verifyPhone}
                  onChange={handleContactInputChange}
                />
                <RadioButton
                  id="notVerify"
                  label={t("join_us.verification.label_remind_text")}
                  value="No"
                  name="verifyPhone"
                  checked={!contactDetails.verifyPhone}
                  onChange={handleContactInputChange}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ContactDetailsCard;
