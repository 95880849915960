import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import LogoIcon from "../icons/LogoIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Button from "../Button/Button";
import useScreenSize from "../../hooks/useScreenSize";
import HamburgerIcon from "../icons/HamburgerIcon";
import CloseIcon from "../icons/CloseIcon";
import Sidebar from "../Sidebar/Sidebar";
import {
  TBreadcrumbItemState,
  TClassNameProps,
  TMenuDataProps,
} from "../../@types/type";
import SwitchLanguage from "../SwitchLanguage/SwitchLanguage";
import { useTranslation } from "react-i18next";
import SearchIcon from "../icons/SearchIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { setOpen } from "../../app/features/searchSlice";
import SearchModale from "../../modals/Search/Search";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";

const Header: React.FC<TClassNameProps> = ({ className }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useScreenSize();
  const dispatch: AppDispatch = useDispatch();
  const { isOpen } = useSelector((state: RootState) => state.search);
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState("/");
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const { t } = useTranslation();
  const links = t("links", { returnObjects: true });

  const headerMenuData: TMenuDataProps[] = [
    {
      title: t("links.home_text"),
      path: "/",
    },
    {
      title: t("links.about_us_text"),
      path: "/about-us",
    },
    {
      title: t("links.events_news_text"),
      path: "/events-news",
    },
    // {
    //   title: t("links.publication_text"),
    //   path: "/publication",
    // },
    {
      title: t("links.media_text"),
      path: "/media",
    },
    {
      title: t("links.contact_us_text"),
      path: "/contact-us",
    },
  ];

  const handleSearchClick = () => {
    dispatch(setOpen(true));
  };

  const handleIconSearchClick = async () => {
    if (searchValue) {
      dispatch(
        setBreadcrumb({
          url: "/search-result",
          name: t("search.name_text"),
          key: "search.name_text",
        })
      );
      navigate(`/search-result?q=${searchValue}`);
      dispatch(setOpen(false));
      setSearchValue("");
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleIconSearchClick();
    }
  };

  const handleJoinUsClick = () => {
    dispatch(
      setBreadcrumb({ url: "join-us", name: "Join Us", key: "navbar_btn_text" })
    );
    navigate("join-us");
  };

  const handleBreadcrumbClick = (item: TMenuDataProps) => {
    const obj: TBreadcrumbItemState = { url: item.path, name: item.title };
    Object.values(links).forEach((value, index) => {
      if (item.title === value) {
        obj.key = `links.${Object.keys(links)[index]}`;
      }
    });

    dispatch(setBreadcrumb(obj));
  };

  useEffect(() => {
    setCurrentPage(location.pathname);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <div className={`${styles.header} ${className}`}>
      <div className={`container ${styles.headerBody}`}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <LogoIcon width="126px" height="90px" fill="" />
        </div>
        <div className={styles.menuData}>
          {headerMenuData?.map((item: TMenuDataProps) => {
            return (
              <Link
                key={uuidv4()}
                to={item.path}
                onClick={() => handleBreadcrumbClick(item)}
                className={item.path === currentPage ? styles.active : ""}
              >
                {item.title}
              </Link>
            );
          })}
        </div>
        <div className={styles.joinUs}>
          <div className={styles.search} onClick={handleSearchClick}>
            <SearchIcon width="30px" height="30px" fill="#333333" />
          </div>
          <div className={styles.language}>
            <SwitchLanguage />
          </div>
          {location.pathname !== "/join-us" ? (
            <Button
              size={width < 1200 ? "small" : "medium"}
              className={className}
              onClick={handleJoinUsClick}
            >
              {t("navbar_btn_text")}
            </Button>
          ) : null}
          <div className={styles.hamburgerMenu}>
            <div
              className={styles.hamburgerIcon}
              onClick={() => setOpenSidebar(!openSidebar)}
            >
              {!openSidebar ? (
                <HamburgerIcon width="24px" height="24px" fill="#333333" />
              ) : (
                <CloseIcon width="24px" height="24px" fill="#333333" />
              )}
            </div>
          </div>
        </div>
        <Sidebar
          isOpen={openSidebar}
          setOpenSidebar={setOpenSidebar}
          menuItems={headerMenuData}
        />
      </div>
      <SearchModale
        isOpen={isOpen}
        searchValue={searchValue}
        onClose={() => dispatch(setOpen(false))}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
        handleKeyPress={handleKeyPress}
        handleSearchClick={handleIconSearchClick}
      />
    </div>
  );
};

export default Header;
