import React from 'react';
import { TIconProps } from '../../@types/icon'

const MobileIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 25" fill="none">
            <path
                d="M8.81143 17.8221L8.64 17.6781C8.57629 17.6327 8.50711 17.5963 8.43429 17.5701L8.22857 17.4741C8.04323 17.4334 7.85116 17.4417 7.66963 17.4982C7.4881 17.5548 7.32279 17.6578 7.18857 17.7981C7.08762 17.9147 7.00645 18.0487 6.94857 18.1941C6.86208 18.4127 6.83912 18.6533 6.88258 18.8857C6.92604 19.118 7.03397 19.3316 7.1928 19.4996C7.35162 19.6677 7.55424 19.7827 7.77515 19.8301C7.99607 19.8775 8.2254 19.8552 8.43429 19.7661C8.57085 19.6965 8.69774 19.6077 8.81143 19.5021C8.97001 19.3333 9.07743 19.119 9.12015 18.8862C9.16286 18.6535 9.13895 18.4126 9.05143 18.1941C8.99445 18.0553 8.91302 17.9291 8.81143 17.8221ZM12.5714 0.674072H3.42857C2.51926 0.674072 1.64719 1.05336 1.00421 1.72849C0.361224 2.40362 0 3.31929 0 4.27407V21.0741C0 22.0289 0.361224 22.9445 1.00421 23.6197C1.64719 24.2948 2.51926 24.6741 3.42857 24.6741H12.5714C13.4807 24.6741 14.3528 24.2948 14.9958 23.6197C15.6388 22.9445 16 22.0289 16 21.0741V4.27407C16 3.31929 15.6388 2.40362 14.9958 1.72849C14.3528 1.05336 13.4807 0.674072 12.5714 0.674072ZM13.7143 21.0741C13.7143 21.3923 13.5939 21.6976 13.3796 21.9226C13.1652 22.1476 12.8745 22.2741 12.5714 22.2741H3.42857C3.12547 22.2741 2.83478 22.1476 2.62045 21.9226C2.40612 21.6976 2.28571 21.3923 2.28571 21.0741V4.27407C2.28571 3.95581 2.40612 3.65059 2.62045 3.42554C2.83478 3.2005 3.12547 3.07407 3.42857 3.07407H12.5714C12.8745 3.07407 13.1652 3.2005 13.3796 3.42554C13.5939 3.65059 13.7143 3.95581 13.7143 4.27407V21.0741Z"
                fill={fill}
            />
        </svg>
    )
}

export default MobileIcon;
