import { ActionReducerMapBuilder, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { signIn } from "../api";

type TAuthState = {
    userInfo: Record<string, unknown>;
    token: string | null;
    isError: boolean | null;
    status: string | null;
    isLoading: boolean;
}

const initialState: TAuthState = {
    userInfo: {},
    token: localStorage.access_token ? localStorage.access_token : '',
    isError: null,
    status: null,
    isLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = ''
            state.userInfo = {}
            localStorage.access_token = ''
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<any>) => {
        builder.addCase(signIn.pending, (state: TAuthState) => {
            state.isLoading = true;
        });
        builder.addCase(signIn.fulfilled, (state: TAuthState, action: PayloadAction<any>) => {
            state.isLoading = false;
            if (action.payload.status === 201) {
                state.token = action.payload.data.access_token
                localStorage.access_token = action.payload.data.access_token;
            }
        });
        builder.addCase(signIn.rejected, (state: TAuthState) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;