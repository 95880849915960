import React from 'react';
import { TIconProps } from '../../@types/icon'

const TwitterIcon = ({ fill, width, height }: TIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 35" fill="none">
            <rect y="0.674072" width="34" height="34" rx="17" fill="#EC1C2B" />
            <path
                d="M9.04432 10.6741L15.2198 18.9326L9 25.6473H10.3961L15.8403 19.7673L20.2355 25.6473H25L18.47 16.9234L24.2613 10.6741H22.8652L17.8495 16.0887L13.8015 10.6741H9.04432ZM11.1053 11.7082H13.2918L22.9538 24.6279H20.7673L11.1053 11.7082Z"
                fill={fill}
            />
        </svg>
    )
}

export default TwitterIcon;
