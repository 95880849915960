import React from "react";
import styles from "./Search.module.scss";
import CloseIcon from "../../components/icons/CloseIcon";
import InputField from "../../components/InputField/InputField";
import SearchIcon from "../../components/icons/SearchIcon";

type TSearchProps = {
  isOpen: boolean;
  searchValue: string;
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearchClick?: () => void;
};

const Search: React.FC<TSearchProps> = ({
  isOpen,
  searchValue,
  onClose,
  onChange,
  handleKeyPress,
  handleSearchClick,
}) => {
  return (
    <div
      className={`${styles.searchModal} ${
        isOpen ? styles.topToBottom : styles.bottomToTop
      }`}
    >
      <div onClick={onClose} className={styles.closeBtn}>
        <CloseIcon width="28px" height="28px" fill="#EC1C2B" />
      </div>
      <div className={styles.searchField}>
        <InputField
          id="searchId"
          value={searchValue}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a keyword to search here"
          inputIcon={<SearchIcon width="28px" height="28px" fill="#333333" />}
          handleInputIconClick={handleSearchClick}
        />
      </div>
    </div>
  );
};

export default Search;
