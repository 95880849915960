import React from 'react';
import { TIconProps } from '../../@types/icon';

const InstagramIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 35" fill="none">
      <rect y="0.674072" width="34" height="34" rx="17" fill="#EC1C2B" />
      <path
        d="M17.0018 13.5364C14.7316 13.5364 12.9005 15.3513 12.9005 17.6013C12.9005 19.8513 14.7316 21.6662 17.0018 21.6662C19.2719 21.6662 21.1031 19.8513 21.1031 17.6013C21.1031 15.3513 19.2719 13.5364 17.0018 13.5364ZM17.0018 20.244C15.5347 20.244 14.3354 19.0588 14.3354 17.6013C14.3354 16.1437 15.5312 14.9586 17.0018 14.9586C18.4724 14.9586 19.6682 16.1437 19.6682 17.6013C19.6682 19.0588 18.4688 20.244 17.0018 20.244ZM22.2274 13.3701C22.2274 13.8973 21.7991 14.3183 21.2708 14.3183C20.739 14.3183 20.3142 13.8937 20.3142 13.3701C20.3142 12.8466 20.7426 12.422 21.2708 12.422C21.7991 12.422 22.2274 12.8466 22.2274 13.3701ZM24.9438 14.3324C24.8831 13.0624 24.5904 11.9374 23.6516 11.0105C22.7165 10.0836 21.5814 9.79347 20.2999 9.72979C18.9793 9.6555 15.0207 9.6555 13.7001 9.72979C12.4222 9.78993 11.2871 10.08 10.3484 11.0069C9.40959 11.9338 9.12047 13.0588 9.05622 14.3289C8.98126 15.6378 8.98126 19.5612 9.05622 20.8702C9.1169 22.1402 9.40959 23.2652 10.3484 24.1921C11.2871 25.119 12.4186 25.4091 13.7001 25.4728C15.0207 25.5471 18.9793 25.5471 20.2999 25.4728C21.5814 25.4127 22.7165 25.1226 23.6516 24.1921C24.5868 23.2652 24.8795 22.1402 24.9438 20.8702C25.0187 19.5612 25.0187 15.6414 24.9438 14.3324ZM23.2376 22.2747C22.9592 22.9681 22.4202 23.5023 21.717 23.7818C20.664 24.1957 18.1654 24.1001 17.0018 24.1001C15.8381 24.1001 13.336 24.1921 12.2866 23.7818C11.5869 23.5058 11.048 22.9716 10.766 22.2747C10.3484 21.231 10.4447 18.7546 10.4447 17.6013C10.4447 16.448 10.3519 13.968 10.766 12.9279C11.0444 12.2345 11.5834 11.7003 12.2866 11.4208C13.3395 11.0069 15.8381 11.1024 17.0018 11.1024C18.1654 11.1024 20.6676 11.0105 21.717 11.4208C22.4166 11.6968 22.9556 12.231 23.2376 12.9279C23.6552 13.9716 23.5588 16.448 23.5588 17.6013C23.5588 18.7546 23.6552 21.2346 23.2376 22.2747Z"
        fill={fill}
      />
    </svg>
  )
}

export default InstagramIcon;
