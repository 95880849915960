import React from "react";
import styles from "./LatestNews.module.scss";
import HrefIcon from "../../components/icons/HrefIcon";
import { TMediaProps } from "../../@types/type";
import NewsCard from "../../components/NewsCard/NewsCard";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../../app/features/tabSlice";
import { MediaType } from "../../enums/enum";
import { RootState } from "../../app/store";
import { clearMedia } from "../../app/features/mediaSlice";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";

const LatestNews = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { news } = useSelector((state: RootState) => state.media);

  const handleNewsViewClick = () => {
    dispatch(setSelectedTab(MediaType.NEWS));
    dispatch(clearMedia(MediaType.EVENT));
    dispatch(
      setBreadcrumb({
        url: "/media",
        name: t("links.media_text"),
        key: "links.media_text",
      })
    );
    navigate("media");
  };

  return (
    <div className={styles.newsSection}>
      <div className={`container ${styles.latestNews}`}>
        <div className={styles.newsHeader}>
          <h3 className={styles.newsTitle}>
            {t("latest_title_text")}{" "}
            <span>{t("home_page.news.title_text")}</span>
          </h3>
          <div className={styles.newsViewAll} onClick={handleNewsViewClick}>
            {i18n.dir() === "rtl" && (
              <HrefIcon width="12px" height="13px" fill="#EC1C2B" />
            )}
            <span>{t("view_all_text")}</span>
            {i18n.dir() === "ltr" && (
              <HrefIcon width="12px" height="13px" fill="#EC1C2B" />
            )}
          </div>
        </div>
        <div className={styles.newsCards}>
          {news.map((news: TMediaProps) => {
            return <NewsCard key={uuidv4()} {...news} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
