import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './features/languageSlice';
import authReducer from './features/authSlice';
import searchReducer from './features/searchSlice';
import eventNewsReducer from './features/eventNewsSlice';
import tabReducer from './features/tabSlice';
import breadcrumbReducer from './features/breadcrumbSlice';
import joinUsReducer from './features/joinUsSlice';
import mainReducer from './features/mainSlice';
import publicationReducer from './features/publicationSlice';
import mediaReducer from './features/mediaSlice';
import contactUsReducer from './features/contactUsSlice';
import aboutUsReducer from './features/aboutUsSlice';
import roleReducer from './features/roleSlice';
import disabledBtnReducer from './features/disabledBtnSlice';
import itemEditReducer from './features/itemEditSlice';
import newsletterReducer from './features/newsletterSlice'
import usersSlice from './features/usersSlice';

export const store = configureStore({
    reducer: {
        tab: tabReducer,
        main: mainReducer,
        role: roleReducer,
        user: usersSlice,
        auth: authReducer,
        media: mediaReducer,
        search: searchReducer,
        joinUs: joinUsReducer,
        aboutUs: aboutUsReducer,
        language: languageReducer,
        itemEdit: itemEditReducer,
        contactUs: contactUsReducer,
        disabled: disabledBtnReducer,
        event_news: eventNewsReducer,
        breadcrumb: breadcrumbReducer,
        publication: publicationReducer,
        newsletter: newsletterReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActionPaths: ["meta.arg", "payload"],
            },
        }),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
