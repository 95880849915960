import React from "react";
import styles from "./Footer.module.scss";
import FooterLogoIcon from "../icons/FooterLogoIcon";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import MobileIcon from "../icons/MobileIcon";
import EmailIcon from "../icons/EmailIcon";
import TwitterIcon from "../icons/TwitterIcon";
import InstagramIcon from "../icons/InstagramIcon";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { TBreadcrumbItemState, TQuickLinksProps } from "../../@types/type";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";

const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const links = t("links", { returnObjects: true });

  const quickLinksData: TQuickLinksProps[] = [
    {
      title: t("links.about_us_text"),
      path: "/about-us",
    },
    {
      title: t("links.publication_text"),
      path: "/publication",
    },
    {
      title: t("links.events_news_text"),
      path: "/media",
    },
  ];

  const handleBreadcrumbClick = (item: TQuickLinksProps) => {
    let obj: TBreadcrumbItemState = { url: item.path, name: item.title };
    Object.values(links).forEach((value, index) => {
      if (item.title === value) {
        obj.key = `links.${Object.keys(links)[index]}`;
      }
    });

    dispatch(setBreadcrumb(obj));
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footerBody}>
        <div className={styles.footerInfo}>
          <div className={styles.footerLogo}>
            <FooterLogoIcon width="126px" height="90px" fill="#FFFFFF" />
            <div>
              <p>{t("footer.info_title_text")}</p>
            </div>
          </div>
          <div className={styles.quickLinks}>
            <h4>{t("footer.links_text")}</h4>
            <div className={styles.linkData}>
              {quickLinksData?.map((item: TQuickLinksProps) => {
                return (
                  <Link
                    key={uuidv4()}
                    to={item.path}
                    onClick={() => handleBreadcrumbClick(item)}
                  >
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </div>
          <div className={styles.touch}>
            <h4>{t("footer.touch_text")}</h4>
            <div className={styles.touchData}>
              <div className={styles.contactForm}>
                <div className={styles.contact}>
                  <MobileIcon width="16px" height="25px" fill="#E6E6E6" />
                  <span>
                    <a href="tel:+971 54 583 9765">+971 54 583 9765</a>
                  </span>
                </div>
                <div className={styles.contact}>
                  <EmailIcon width="22px" height="18px" fill="#E6E6E6" />
                  <span>
                    <a href="mailto:pr@spsociety.ai">pr@spsociety.ai</a>
                  </span>
                </div>
              </div>
              <div className={styles.footerIcons}>
                <a
                  href="https://twitter.com/SPSocietyai"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon width="34" height="35" fill="#FFFFFF" />
                </a>
                <a
                  href="https://www.instagram.com/SpSociety.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon width="34" height="35" fill="#FFFFFF" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.copyRignt}>
        Copyright © {new Date().getFullYear()} SPS, All Rights Reserved. |
        Sitemap
      </div>
    </div>
  );
};

export default Footer;
