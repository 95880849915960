import { TUserProps } from "../../../../@types/type";
import { v4 as uuidv4 } from "uuid";
import styles from "./UsersTable.module.scss"
import FileInputButton from "../../../components/FileInputButton/FileInputButton";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../app/store";
import { searchUser } from "../../../../app/api";
import { useState } from "react";
import Verified from "../../../../components/icons/admin/Verified";
import NotVerified from "../../../../components/icons/admin/NotVerified";
import MembershipIdUpload from "../../../components/MembershipIdGenerator/MembershipIdGenerator";
import { format } from "date-fns";

type TUsersTeableProps = {
  rowsData: TUserProps[];
  headerColumns: string[];
};

const UsersTable: React.FC<TUsersTeableProps> = (props) => {
  const { rowsData, headerColumns } = props;
  const dispatch = useDispatch<AppDispatch>()
  const [searchValue, setSearchValue] = useState<string>("")

  const hadleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target.value
    if (value) {
      setSearchValue(value)
    }
  }

  const handleSearchClick = async () => {
    await dispatch(searchUser(searchValue))
  }

  return <div className={styles.tableContainer}>
    <div className={styles.topActions}>
      <div className={styles.inputsContainer}>
        <FileInputButton />
        <MembershipIdUpload />
        <div className={styles.searchInput}>
          <input
            value={searchValue}
            placeholder={"Search by Company Name/Job Title"}
            onChange={hadleSearchChange}
          />
          <button className={styles.searchInputButton} onClick={handleSearchClick}>search</button>
        </div>
      </div>
    </div>
    {
      rowsData.length ? <div className={styles.body}>
        <table>
          <thead>
            <tr>
              {headerColumns.map((col: any) => {
                return <th key={uuidv4()}>{col}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {
              rowsData.map((row: any) => {
                const { membershipId, firstName, lastName, email, phone, birthdate, gender, nationality, residency, security, jobTitle, companyName, country, licenseIssuingCountry, licenseIdNumber, licenseFile, contribution, isEmailConfirmed, isPhoneConfirmed, createdAt } = row
                const createdDate = format(new Date(createdAt), "dd MMM yyyy")
                
                return (
                  <tr key={uuidv4()}>
                    <td><p>{membershipId}</p></td>
                    <td><p>{firstName ? firstName : '-'}</p></td>
                    <td><p>{lastName ? lastName : '-'}</p></td>
                    <td><p>{email ? email : '-'}</p></td>
                    <td><p>{phone ? `+${phone}` : '-'}</p></td>
                    <td><p>{birthdate ? birthdate : '-'}</p></td>
                    <td><p>{gender ? gender : '-'}</p></td>
                    <td><p>{nationality ? nationality : '-'}</p></td>
                    <td><p>{residency ? residency : '-'}</p></td>
                    <td><p>{security ? security : '-'}</p></td>
                    <td><p>{jobTitle ? jobTitle : '-'}</p></td>
                    <td><p>{companyName ? companyName : '-'}</p></td>
                    <td><p>{country ? country : '-'}</p></td>
                    <td><p>{licenseIssuingCountry ? licenseIssuingCountry : '-'}</p></td>
                    <td><p>{licenseIdNumber ? licenseIdNumber : '-'}</p></td>
                    <td><p>{licenseFile ? licenseFile : '-'}</p></td>
                    <td><p>{contribution ? contribution : '-'}</p></td>
                    <td><p>{isEmailConfirmed ? <Verified width='20' height="20" fill='currentColor' /> : <NotVerified width='20' height="20" fill='currentColor'/>}</p></td>
                    <td><p>{isPhoneConfirmed ? <Verified width='20' height="20" fill='currentColor' /> : <NotVerified width='20' height="20" fill='currentColor'/>}</p></td>
                    <td><p>{security ? 4 : 3}</p></td>
                    <td><p>{createdAt ? createdDate : '-'}</p></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div> : <p>Users not found</p>
    }
  </div>
}

export default UsersTable