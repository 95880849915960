import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./JoinUs.module.scss";
import Button from "../../components/Button/Button";
import PersonalDetailsCard from "./Crads/PersonalDetailsCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import CompanyDetailsCard from "./Crads/CompanyDetailsCard";
import ContactDetailsCard from "./Crads/ContactDetailsCard";
import {
  sendVerificationCode,
  registerUser,
  confirmVerificationCode,
  getMemberById,
  verifyUserEmail,
} from "../../app/api";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import OTPVerificationForm from "./Crads/OTPVerificationForm";
import SuccessIcon from "../../components/icons/SuccessIcon";
import Loading from "../../components/Loading/Loading";
import { useSearchParams } from "react-router-dom";
import { clearJoinUsState } from "../../app/features/joinUsSlice";
import { TFindByIdApiProps } from "../../@types/type";
import { toast } from "react-toastify";
import SuccessMessage from "./Crads/SuccessMessage";

const steps = [
  {
    step: 1,
  },
  {
    step: 2,
  },
  {
    step: 3,
  },
  {
    step: 4,
  },
];

const JoinUs = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [step, setStep] = useState<number>(1);
  const [isCardsValid, setIsCardsValid] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormSuccess, setIsFormSuccess] = useState<boolean>(false);
  const numberOfDigits = 6;
  const [otp, setOtp] = useState<string[]>(new Array(numberOfDigits).fill(""));
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const {
    personalDetails,
    companyDetails,
    contactDetails,
    cardDetails,
    isError,
    isLoading,
    otpCode,
    isCodeSuccessfullyVerified,
    isEmailVerified
  } = useSelector((state: RootState) => state.joinUs);

  const handleNextClick = () => {
    setStep(step + 1);
  };

  const handleFisrtStepClick = async () => {
    const query: any = {
      ...contactDetails,
    };
      await dispatch(verifyUserEmail({email: query.email}))
      if(isEmailVerified) {
        handleSendVerificationCode()
      } else {
        toast.warning(t("join_us.verification.email_already_exists"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }      
  }

  const handleSendVerificationCode = async () => {
    if(contactDetails.verifyPhone) {
      await dispatch(sendVerificationCode({ phoneNumber: contactDetails.phone })).then(
        () => setStep(2)
      );
    } else {
      setStep(3)
    }
  }

  const handleResendClick = async () => {
    const query: any = {
      ...contactDetails,
    };
    await dispatch(sendVerificationCode({ phoneNumber: query.phone })).then(
      (res: any) => {
        toast.success(t("join_us.verification.resent_code_successfully_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const handleJoinClick = async () => {
    const query: any = {
      ...personalDetails,
      ...companyDetails,
      ...contactDetails,
      ...cardDetails,
      isCodeSuccessfullyVerified: isCodeSuccessfullyVerified,
      otpCode: otpCode,
    };

    const formData: any = new FormData();

    formData.append("firstName", query.firstName);
    formData.append("lastName", query.lastName);
    formData.append("userId", query.userId);
    formData.append("verifyPhone", query.verifyPhone);
    formData.append("birthdate", query.date);
    formData.append("gender", query.gender);
    formData.append("nationality", query.nationality);
    formData.append("residency", query.residency);
    formData.append("email", query.email);
    formData.append("phone", query.phone);
    formData.append("otpCode", query.otpCode);
    formData.append("role", "Member");
    formData.append("isCodeSuccessfullyVerified", query.isCodeSuccessfullyVerified)

    if (query.securityIndustry) {
      formData.append("security", query.security);
      formData.append("jobTitle", query.jobTitle);
      formData.append("companyName", query.companyName);
      formData.append("country", query.country);
      formData.append("licenseIssuingCountry", query.licenseCountry);
      formData.append("licenseIdNumber", query.licenseIDNumber);

      if (query.multipleContribution.length) {
        query.multipleContribution.forEach((item: any) => {
          formData.append("contribution", item);
        });
      } else {
        formData.append("contribution", "");
      }

      if (query.selectedFile !== null) {
        formData.append("licenseFile", query.selectedFile);
      }

      if (query.residency === "United Arab Emirates") {
        formData.append("emiratesIdNumber", query.emiratesIdNumber)
        formData.append("emiratesIdExpiredDate", query.emiratesIdExpiredDate)
        formData.append("martialStatus", query.martialStatus)
      }
    }

    await dispatch(registerUser(formData)).then((res: any) => {
      setIsFormSuccess(true);
      dispatch(clearJoinUsState());
    });
  };

  const handleVerifyClick = async () => {
    const query: any = {
      ...contactDetails,
      ...personalDetails,
      otpCode,
    };

    await dispatch(
      confirmVerificationCode({
        phoneNumber: query.phone,
        verificationCode: query.otpCode
      })
    ).then((res: any) => {
      if (res.payload.data.isCodeVerified) {
        toast.success(
          t("join_us.verification.phone_successfully_verified_text"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setStep(3);
      } else {
        toast.warning(t("join_us.verification.wrong_code_text"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };

  const handleSetOtp = (data: string[]) => {
    setOtp([...data]);
  };

  useEffect(() => {
    const isValidPersonalDetails = Object.values(personalDetails).every(
      (value) => {
        return value;
      }
    );

    const isValidContactDetails = Object.values(contactDetails).every(
      (value) => {
        if (typeof value !== "boolean") {
          return value;
        }
        return true;
      }
    );

    const isValidCompanyDetails =
      step === 4 ? Object.entries(companyDetails).every(([key, value]: any) => {        
        if (
          key === "multipleContribution" &&
          companyDetails.contribute === "Yes"
        ) {
          return value.length;
        } else if(key === "selectedFile") {
          return true
        } else {
          return value;
        }
      }) : true

    const isFormValid =
      isValidPersonalDetails &&
      isValidCompanyDetails &&
      isValidContactDetails &&
      isError;

    setIsFormValid(isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetails, companyDetails, contactDetails, isError, step]);

  useEffect(() => {
    if (userId) {
      const apiProps: TFindByIdApiProps = {
        id: userId,
      };
      dispatch(getMemberById(apiProps));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [step]);

  return (
    <div className={`container ${styles.joinUsPageGrid}`}>
      {isFormSuccess ? (
        <div className={styles.successMessage}>
          <SuccessMessage />
        </div>
      ) : (
        <div className={styles.joinUsFormContainer}>
          <h2>{t("join_us.title_text")}</h2>
          <ProgressBar activeStep={step} steps={steps} />
          {step !== 2 ? (
            <p className={styles.subTitle}>{t("join_us.sub_title_text")}</p>
          ) : null}
          <div className={styles.form}>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {step === 1 ? (
                  <ContactDetailsCard setIsCardsValid={setIsCardsValid} />
                ) : step === 2 ? (
                  <OTPVerificationForm
                    otp={otp}
                    handleSetOtp={handleSetOtp}
                    numberOfDigits={numberOfDigits}
                  />
                ) : step === 3 ? (
                  <PersonalDetailsCard setIsCardsValid={setIsCardsValid} />
                ) : (
                  <CompanyDetailsCard
                    setIsCardsValid={setIsCardsValid}
                    selectedFileName={companyDetails?.selectedFile}
                  />
                )}
              </>
            )}
          </div>
          <div
            className={`${styles.nextBtn} ${step !== 1 && step !== 3 ? styles.btnStyle : ""
              }`}
          >
            {step === 4 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStep(step - 1)}
              >
                {t("join_us.back_btn_text")}
              </Button>
            ) : step === 2 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleResendClick}
              >
                {t("join_us.verification.resend")}
              </Button>
            ) : null}
            {step === 4 ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!isFormValid || isLoading}
                onClick={handleJoinClick}
              >
                {t("join_us.btn_text")}
              </Button>
            ) : step === 3 && contactDetails.securityIndustry ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!isCardsValid}
                onClick={handleNextClick}
              >
                {t("join_us.next_btn_text")}
              </Button>
            ) : step === 3 && !contactDetails.securityIndustry ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!isFormValid || isLoading}
                onClick={handleJoinClick}
              >
                {t("join_us.btn_text")}
              </Button>
            ) : step === 1 ? (
              <Button
                variant="contained"
                color="primary"
                disabled={!isCardsValid}
                // onClick={handleAddContactDetails}
                onClick={handleFisrtStepClick}
              >
                {t("join_us.next_btn_text")}
              </Button>
            ) : step === 2 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleVerifyClick}
              >
                {t("join_us.verification.verify")}
              </Button>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default JoinUs;
