import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Link, useNavigate } from "react-router-dom";
import { setBreadcrumb } from "../../app/features/breadcrumbSlice";
import { TBreadcrumbItemState } from "../../@types/type";
import styles from "./Breadcrumb.module.scss";
import { useTranslation } from "react-i18next";

const Breadcrumb: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { breadcrumbItems } = useSelector(
    (state: RootState) => state.breadcrumb
  );

  const handleBreadcrumbItemClick = (
    data: TBreadcrumbItemState,
    idx: number
  ) => {
    if (!data.url && breadcrumbItems.length - 1 !== idx) {
      const lastItemWithUrl = breadcrumbItems
        .slice()
        .reverse()
        .find((item) => item.url);
      if (lastItemWithUrl) {
        dispatch(
          setBreadcrumb({
            url: lastItemWithUrl.url,
            name: lastItemWithUrl.name,
            key: lastItemWithUrl.key,
          })
        );
        navigate(`${lastItemWithUrl.url}`);
      } else {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    // if (!localStorage.breadcrumbs) {
    //   // navigate("/");
    //   navigate("/join-us");
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`container ${styles.breadcrumb}`}>
      {/* <div>
        <nav className={styles.breadcrumbs}>
          {breadcrumbItems.map((item, index: number) => {
            const { url, name, key } = item;
            const isLastItem = breadcrumbItems.length - 1 === index;
            return (
              <Fragment key={index}>
                {url && !isLastItem ? (
                  <Link
                    itemProp="item"
                    className={styles.breadcrumbs__item}
                    to={url}
                    onClick={() => dispatch(setBreadcrumb({ url, name, key }))}
                  >
                    {name}
                  </Link>
                ) : (
                  <span
                    className={`${styles.breadcrumbs__item} ${
                      isLastItem ? styles.isActive : ""
                    } ${!url && !isLastItem ? styles.lastItem : ""}`}
                    onClick={() => handleBreadcrumbItemClick(item, index)}
                  >
                    {t(name)}
                  </span>
                )}
              </Fragment>
            );
          })}
        </nav>
      </div> */}
    </div>
  );
};

export default Breadcrumb;
