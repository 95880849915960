import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Main.module.scss";
import HeroSection from "../../sections/Hero/Hero";
import PartnerSection from "../../sections/Partner/PartnerSection";
import LatestEvents from "../../sections/LatestEvents/LatestEvents";
import LatestNews from "../../sections/LatestNews/LatestNews";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { mediaList, partnerList, slideList } from "../../app/api";
import { MediaType } from "../../enums/enum";
import Loading from "../../components/Loading/Loading";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import { setClearBreadCrumbsArray } from "../../app/features/breadcrumbSlice";

const MainPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [emptyData, setEmptyData] = useState<boolean>(false);
  const { slideData, partnerData, isLoading } = useSelector(
    (state: RootState) => state.main
  );
  const { events, news } = useSelector((state: RootState) => state.media);

  const allMainData = useCallback(async () => {
    try {
      await dispatch(slideList({ take: 100 }));
      await dispatch(partnerList({ take: 100 }));
      await dispatch(mediaList({ type: MediaType.EVENT, take: 4 }));
      await dispatch(mediaList({ type: MediaType.NEWS, take: 3 }));
    } catch (error: unknown) {
      console.error("Error fetching data:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    allMainData();
  }, [allMainData]);

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(setClearBreadCrumbsArray());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (
      !slideData?.length &&
      !partnerData?.length &&
      !events?.length &&
      !news?.length
    ) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }
  }, [slideData, partnerData, events, news]);

  return (
    <div className={styles.mainPage}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {slideData?.length ? <HeroSection /> : null}
          {partnerData?.length ? <PartnerSection /> : null}
          {events?.length ? <LatestEvents /> : null}
          {news?.length ? <LatestNews /> : null}
          {emptyData ? <ComingSoon /> : null}
        </Fragment>
      )}
    </div>
  );
};

export default MainPage;
