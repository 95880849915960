import React, { ChangeEvent, Dispatch, useRef, useState } from "react";
import styles from "../JoinUs.module.scss";
import InputField from "../../../components/InputField/InputField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { setCardDetails, setCompanyDetails } from "../../../app/features/joinUsSlice";
import Uploadicon from "../../../components/icons/admin/Uploadicon";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { attachmentsFilesFormat } from "../../../utils/formats";

type TCompanyDetailsProps = {
  setIsCardsValid: Dispatch<React.SetStateAction<boolean>>;
  selectedFileName: File | null;
};

const CompanyDetailsCard: React.FC<TCompanyDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { companyDetails, personalDetails, cardDetails } = useSelector((state: RootState) => state.joinUs);

  const strFile: any = companyDetails.selectedFile;

  const countries = t("countries", { returnObjects: true });
  const countryOptions = Object.entries(countries)?.map(([index, value]) => {
    return { value: value.code, label: value.name };
  });

  const securityTranslations: string[] = t("security.data", {
    returnObjects: true,
  });
  const security = securityTranslations?.map((sec, index) => {
    const value = (index + 1).toString();
    return { value, label: sec };
  });

  const handleCompanyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "notConfirm" || id === "confirm") {
      dispatch(
        setCompanyDetails({
          key: "contribute",
          value,
        })
      );
    } else if (id === "workshops" || id === "researchPapers") {
      dispatch(
        setCompanyDetails({
          key: "multipleContribution",
          value: value,
        })
      );
    } else {
      dispatch(setCompanyDetails({ key: id, value }));
    }
  };

  const handleCardInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    dispatch(setCardDetails({ id, value }))
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedFile(file);
      dispatch(
        setCompanyDetails({
          key: "file",
          value: event.target.files?.[0],
        })
      );
    }
  };

  const handleResetFile = () => {
    setSelectedFile(null);
    dispatch(
      setCompanyDetails({
        key: "file",
        value: null,
      })
    );
    const fileInput = inputRefs.current[0];
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className={styles.companyDetails}>
      <div>
        <p className={styles.sectionTitle}>
          {t("join_us.company_details_text")}
        </p>
        <div className={styles.sectionContent}>
          <div className={styles.inputField}>
            <p className={styles.dateTitle}>
              {t("join_us.security.label_text")}{" "}
              <span className={styles.required}>&nbsp;*</span>
            </p>
            <div>
              <Dropdown
                defaultValue={t("join_us.security.placeholder_text")}
                options={security}
                selectedValue={
                  companyDetails.selectedSecurity
                    ? companyDetails.selectedSecurity.label
                    : ""
                }
                onValueChange={(selectedValue) =>
                  dispatch(
                    setCompanyDetails({
                      key: "selectedSecurity",
                      value: selectedValue,
                    })
                  )
                }
              />
            </div>
          </div>
          <div className={styles.inputField}>
            <InputField
              id="jobTitle"
              value={companyDetails.jobTitle}
              onChange={handleCompanyInputChange}
              type="string"
              label={t("join_us.job_title.label_text")}
              required
              placeholder={t("join_us.job_title.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="companyName"
              value={companyDetails.companyName}
              onChange={handleCompanyInputChange}
              type="string"
              label={t("join_us.company_name.label_text")}
              required
              placeholder={t("join_us.company_name.placeholder_text")}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              id="country"
              value={companyDetails.country}
              onChange={handleCompanyInputChange}
              type="string"
              label={t("join_us.company_address.label_text")}
              required
              placeholder={t("join_us.company_address.placeholder_text")}
            />
          </div>
        </div>
      </div>
      <div className={styles.licenseDetails}>
        <p className={styles.sectionTitle}>
          {t("join_us.company_license_details_text")}
        </p>
        <div className={styles.sectionContent}>
          <div className={styles.inputField}>
            <p className={styles.dateTitle}>
              {t("join_us.license.label_text")}{" "}
              <span className={styles.required}>&nbsp;*</span>
            </p>
            <div>
              <Dropdown
                defaultValue={t("join_us.license.placeholder_text")}
                search
                inputPlaceholder={t("join_us.license.placeholder_text")}
                options={countryOptions}
                selectedValue={
                  companyDetails.selectedLicenseCountry
                    ? companyDetails.selectedLicenseCountry.label
                    : ""
                }
                onValueChange={(selectedValue) => {
                  dispatch(
                    setCompanyDetails({
                      key: "selectedLicenseCountry",
                      value: selectedValue,
                    })
                  );
                }}
              />
            </div>
          </div>
          <div className={styles.inputField}>
            <InputField
              id="licenseIDNumber"
              value={companyDetails.licenseIDNumber}
              onChange={handleCompanyInputChange}
              type="string"
              label={t("join_us.licenseIDNumber.label_text")}
              required
              placeholder={t("join_us.licenseIDNumber.placeholder_text")}
            />
          </div>
        </div>
        <div className={`${styles.upload} ${styles.fullWidth}`}>
          <div className={styles.upladedFiles}>
            {companyDetails.selectedFile && !selectedFile ? (
              <div className={styles.file}>
                <div className={styles.removeFile} onClick={handleResetFile}>
                  x
                </div>
                {attachmentsFilesFormat(strFile)?.fileName}
              </div>
            ) : selectedFile ? (
              <div className={styles.file}>
                <div className={styles.removeFile} onClick={handleResetFile}>
                  x
                </div>
                {selectedFile.type.startsWith("image/") ? (
                  <img src={URL.createObjectURL(selectedFile)} alt="Uploaded" />
                ) : (
                  <p>{selectedFile.name}</p>
                )}
              </div>
            ) : (
              <div
                className={styles.uploadFile}
                onClick={() => inputRefs.current[0].click()}
              >
                <Uploadicon width="30px" height="30px" fill="#333333" />
                <p>Upload licenses file</p>
              </div>
            )}
          </div>
          <input
            ref={(el) => {
              inputRefs.current[0] = el!;
            }}
            type="file"
            id="fileInput"
            name="fileInput"
            onChange={handleFileChange}
          />
        </div>
        <div className={styles.inputRadioField}>
          <div className={styles.radioTitle}>
            <span>{t("join_us.contribute.title_text")}</span>
            {/* <span className={styles.required}>*</span> */}
          </div>
          <div className={styles.radioField}>
            <RadioButton
              id="confirm"
              label={t("join_us.contribute.label_yes_text")}
              value="Yes"
              name="contribute"
              checked={companyDetails.contribute === "Yes"}
              onChange={handleCompanyInputChange}
            />
            <RadioButton
              id="notConfirm"
              label={t("join_us.contribute.label_no_text")}
              value="No"
              name="contribute"
              checked={companyDetails.contribute === "No"}
              onChange={handleCompanyInputChange}
            />
          </div>
        </div>
        {companyDetails.contribute === "Yes" ? (
          <div className={styles.multipleContribution}>
            <div>
              <Checkbox
                id="workshops"
                checked={companyDetails.multipleContribution?.includes(
                  t("join_us.multiple_contribution_text_workshops")
                )}
                value={t("join_us.multiple_contribution_text_workshops")}
                onChange={handleCompanyInputChange}
              />
              <div className={styles.text}>
                {t("join_us.multiple_contribution_text_workshops")}
              </div>
            </div>
            <div>
              <Checkbox
                id="researchPapers"
                checked={companyDetails.multipleContribution?.includes(
                  t("join_us.multiple_contribution_text_research_papers")
                )}
                value={t("join_us.multiple_contribution_text_research_papers")}
                onChange={handleCompanyInputChange}
              />
              <div className={styles.text}>
                {t("join_us.multiple_contribution_text_research_papers")}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className={styles.cardDetails}>
      {
        personalDetails.residency === "United Arab Emirates" ? <p>
          {t("join_us.card_details_text")}
        </p> : null
      }
        {
          personalDetails.residency === "United Arab Emirates" ? <div>
            <div className={styles.sectionContent}>
              <div className={styles.inputField}>
                <InputField
                  id="emiratesIdNumber"
                  value={cardDetails.emiratesIdNumber}
                  onChange={handleCardInputsChange}
                  type="string"
                  label={t("join_us.emiratesIdNumber.label_text")}
                  placeholder={t("join_us.emiratesIdNumber.placeholder_text")}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  id="emiratesIdExpiredDate"
                  value={cardDetails.emiratesIdExpiredDate}
                  onChange={handleCardInputsChange}
                  type="date"
                  label={t("join_us.emiratesIdExpiredDate.label_text")}
                  placeholder={t("join_us.emiratesIdExpiredDate.placeholder_text")}
                />
              </div>
            </div>

            <div className={styles.martialStatus}>
              <div className={styles.inputRadioField}>
                <div className={styles.radioTitle}>
                  <span>{t("join_us.martialStatus.title_text")}</span>
                </div>
                <div className={styles.radioField}>
                  <RadioButton
                    id="martialStatus"
                    label={t("join_us.martialStatus.label_married_text")}
                    value="Married"
                    name="martialStatus"
                    checked={cardDetails.martialStatus === "Married"}
                    onChange={handleCardInputsChange}
                  />
                  <RadioButton
                    id="martialStatus"
                    label={t("join_us.martialStatus.label_single_text")}
                    value="Single"
                    name="martialStatus"
                    checked={cardDetails.martialStatus === "Single"}
                    onChange={handleCardInputsChange}
                  />
                </div>
              </div>
            </div>
          </div> : null
        }
      </div>
    </div>
  );
};

export default CompanyDetailsCard;