import { useEffect, useRef } from "react";
import styles from "./ProgressBar.module.scss";

type TPersonalDetailsProps = {
  activeStep: number;
  steps: any[];
};

const ProgressBar: React.FC<TPersonalDetailsProps> = ({
  activeStep,
  steps,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.setProperty("--width", width);
    }
  }, [activeStep, width]);

  return (
    <div className={styles.stepContainer} ref={containerRef}>
      {steps.map(({ step }) => {
        return (
          <div className={styles.stepWrapper} key={step}>
            <div
              className={`${styles.stepStyle} ${
                activeStep >= step ? styles.completed : ""
              }`}
            >
              {activeStep > step ? (
                <div className={styles.checkMark}>L</div>
              ) : (
                <div className={styles.stepCount}>{step}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
