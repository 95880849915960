import React from "react";
import { TIconProps } from "../../@types/icon";

const FooterLogoIcon = ({ fill, width, height }: TIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 1024 768"
    >
      <defs>
        <clipPath id="clip_0">
          <path transform="matrix(1,0,0,-1,0,768)" d="M0 768H1024V0H0Z" />
        </clipPath>
      </defs>
      <g id="layer_1" data-name="">
        <g clipPath="url(#clip_0)">
          <path
            transform="matrix(1,0,0,-1,529.0924,128.0426)"
            d="M0 0C1.575-14.028 15.323-23.994 30.566-22.207 45.743-20.371 56.619-7.594 54.928 6.582 54.583 9.328 53.627 11.88 52.49 14.333 64.096 10.531 70.201 3.219 67.45-8.893 79.087 5.763 80.254 21.833 59.815 27.243 21.561 37.178-37.909 29.765-106.831-8.572-76.108 2.066-33.347 13.691 3.843 16.248 .768 11.574-.706 5.886 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,658.6419,147.03473)"
            d="M0 0C.082-.752-.001-1.488-.348-2.24 4.623-.984 7.978 2.986 7.519 7.122 6.845 11.913 1.729 15.17-4.068 14.471-9.931 13.773-14.178 9.378-13.604 4.718V4.555C-12.679 4.881-11.589 5.125-10.565 5.288-5.115 5.873-.344 3.581 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,465.2324,177.255)"
            d="M0 0C-28.389-71.44 23.513-93.008 72.022-57.697 103.282-35.028 126.135 8.133 132.339 24.951 122.454 11.323 16.789-51.2 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,635.5814,377.9537)"
            d="M0 0C.113-.193-.661 .339-2.097 1.501-2.275 2.34-2.453 3.163-2.662 3.969-1.823 2.646-.919 1.323 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,718.7142,251.96363)"
            d="M0 0C8.342 26.947-18.96 48.23-26.35 38.904-33.659 29.593-60.461 21.703-80.115 15.329-107.046-3.373-125.554-46.569-96.655-103.464-93.589-109.499-89.991-115.679-85.795-122.021-85.585-122.827-85.407-123.65-85.23-124.489-89.942-120.697-101.77-110.047-112.549-94.944-136.333-61.672-155.099-6.793-81.567 43.825-97.477 38.339-113.581 33.676-140.851 23.026-126.458 32.562-104.384 47.666-81.938 61.865-40.759 74.193-7.858 63.285-1.484 47.698 22.704 138.221-79.631 136.882-93.379 135.252-120.955 166.282-190.453 175.076-251.689 157.1-302.292 142.287-343.616 91.878-371.515 49.618-365.206 57.751-309.972 90.459-277.475 102.851-358.542 36.596-410.661-14.006-453.938-93.911-464.797-113.936-475.092-135.817-485.145-160.231-501.588-200.151-510.117-222.455-526.673-279.125-524.026-262.924-515.723-224.242-511.091-207.299-511.511-206.912-504.605-189.195-505.137-187.21-499.716-169.735-493.406-152.082-486.388-134.478-482.16-123.828-477.658-113.194-472.93-102.625-417.39 21.687-330.965 136.72-272.795 159.052-192.487 189.792-122.295 176.77-78.888 142.868 51.991 125.474 12.78 16.007 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,635.5814,377.9537)"
            d="M0 0C.113-.193-.661 .339-2.097 1.501-2.275 2.34-2.453 3.163-2.662 3.969-1.823 2.646-.919 1.323 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,604.5842,415.97)"
            d="M0 0C0-10.956-6.325-16.959-19.864-16.959H-120.036V44.632H-19.864C-6.325 44.632 0 38.63 0 27.673ZM29.965 29.948C27.608 30.174 25.608 31.465 19.67 36.79 16.411 39.711 11.344 44.519 5.712 50.909 1.001 56.266-4.47 63.156-9.617 71.241-12.812 71.612-16.216 71.789-19.864 71.789H-150.048V-111.968H-120.036V-44.1H-19.864C16.249-44.1 30.013-26.092 30.013 .258V27.415C30.013 28.27 29.997 29.109 29.965 29.948"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,800.2498,416.4863)"
            d="M0 0H-93.412C-106.95 0-113.275 6.003-113.275 16.959V28.19C-113.275 39.146-106.95 45.149-93.412 45.149H41.066V72.305H-93.412C-129.508 72.305-143.272 54.281-143.272 27.931V17.217C-143.272-9.133-129.508-27.141-93.412-27.141H0C13.538-27.141 19.864-33.143 19.864-44.116V-67.336C19.864-78.308 13.538-84.311 0-84.311H-138.092V-111.451H0C36.096-111.451 49.86-93.444 49.86-67.077V-44.374C49.86-18.008 36.096 0 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,375.566,416.4863)"
            d="M0 0H-93.427C-106.965 0-113.275 6.003-113.275 16.959V28.19C-113.275 39.146-106.965 45.149-93.427 45.149H41.066V72.305H-93.427C-95.993 72.305-98.462 72.209-100.802 72.031L-137.834-7.003C-130.895-19.541-116.873-27.141-93.427-27.141H0C13.538-27.141 19.847-33.143 19.847-44.116V-67.336C19.847-78.308 13.538-84.311 0-84.311H-138.092V-111.451H0C36.096-111.451 49.86-93.444 49.86-67.077V-44.374C49.86-18.008 36.096 0 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M173.245 150.757H167.521V145.005H173.245Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M259.039 107.479H264.764V113.28H259.039Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M249.732 107.479H255.457V113.28H249.732Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M271.009 107.479H276.73503V113.28H271.009Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M280.317 107.479H286.043V113.28H280.317Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M307.42 150.757H301.696V145.005H307.42Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,331.5977,636.2881)"
            d="M0 0C3.107 0 5.362-2.68 5.362-6.371 5.362-9.949 3.279-12.448 .296-12.448-2.868-12.448-5.165-9.789-5.165-6.126-5.165-2.519-3.041 0 0 0M.148 5.261C-6.496 5.261-11.135 .558-11.135-6.175-11.135-6.61-11.09-7.04-11.034-7.53L-11.022-7.591H-15.905-17.784-25.205C-24.515-6.562-24.178-4.745-24.178-2.054V9.088H-29.953V-2.349C-29.953-4.043-30.151-5.306-30.526-6.006-31.084-7.072-32.118-7.591-33.688-7.591H-37.332-38.965-46.425C-45.707-6.574-45.357-4.757-45.357-2.054V9.088H-51.132V-2.349C-51.132-4.04-51.33-5.305-51.705-6.006-52.266-7.072-53.316-7.591-54.917-7.591H-58.609-60.341-67.704C-66.986-6.575-66.636-4.757-66.636-2.054V9.088H-72.411V-2.349C-72.411-4.043-72.609-5.306-72.983-6.006-73.543-7.072-74.593-7.591-76.196-7.591H-80.034-81.62-89.786-91.224-99.539-100.977-109.291-110.731-119.044-120.483-128.796-130.236-137.914-139.988-145.911C-145.733-6.607-145.646-5.66-145.646-4.703V9.088H-151.519V-4.065C-151.519-7.231-152.322-9.974-153.902-12.215-155.794-14.778-158.287-16.077-161.313-16.077-164.371-16.077-166.881-14.778-168.77-12.218-170.386-9.928-171.205-7.183-171.205-4.065V9.088H-177.078V-4.703C-177.078-9.447-175.49-13.509-172.358-16.778-169.323-19.999-165.607-21.633-161.313-21.633-158.456-21.633-155.883-20.886-153.452-19.353-151.141-17.935-149.332-16.016-147.924-13.49H-139.988-137.914-130.236-128.796-120.483-119.044-110.731-109.291-100.977-99.539-91.224-89.786-81.62-80.034-60.341-58.609-38.965-37.332-17.784-15.905-8.845C-6.696-16.387-3.67-17.856 .148-17.856 6.733-17.856 11.333-13.133 11.333-6.371 11.333 .369 6.628 5.261 .148 5.261"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,365.7738,638.2441)"
            d="M0 0C0-2.202-.437-3.707-1.334-4.601-2.021-5.287-3.016-5.617-4.455-5.633L-10.58 16.817-10.611 16.93H-16.742L-10.537-5.635H-18.024V-11.534H-3.146C2.213-11.534 5.676-8.451 5.676-3.679V20.413H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,356.5768,616.7025)"
            d="M0 0H-4.099C-4.607 .313-4.875 .885-4.875 1.661-4.875 2.502-4.306 3.372-3.355 3.372-2.658 3.372-2.256 3.059-2.125 2.416L-2.085 2.215 .001 3.311-.064 3.445C-.786 4.934-1.876 5.69-3.305 5.69-5.582 5.69-7.301 3.916-7.301 1.563-7.301 1.331-7.194 .898-6.912 0H-8.827V-2.563H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M376.318 118.222H382.093V150.218H376.318Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M415.487 150.757H409.761V145.005H415.487Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M501.28 107.479H507.004V113.28H501.28Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M491.973 107.479H497.697V113.28H491.973Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M534.242 150.856H528.566V145.104H534.242Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,531.2319,632.0202)"
            d="M0 0C3.187 0 5.411-2.599 5.411-6.322 5.411-9.956 3.355-12.399 .296-12.399-2.789-12.399-5.116-9.702-5.116-6.126-5.116-2.404-3.107 0 0 0M.197 5.31C-6.447 5.31-11.086 .587-11.086-6.175-11.086-8.199-10.639-10.11-9.757-11.858H-16.002-17.488-25.098C-24.38-10.841-24.03-9.024-24.03-6.322V4.82H-29.805V-6.617C-29.805-8.31-30.003-9.574-30.378-10.274-30.936-11.34-31.986-11.858-33.59-11.858H-37.426-39.014-47.179-48.62-56.932-58.372-66.684-68.125-76.437-77.877-86.191-87.63-95.308-97.383-103.304C-103.126-10.875-103.039-9.928-103.039-8.97V4.82H-108.912V-8.333C-108.912-11.497-109.715-14.24-111.296-16.483-113.189-19.045-115.681-20.345-118.707-20.345-121.807-20.345-124.247-19.081-126.165-16.486-127.781-14.193-128.6-11.45-128.6-8.333V4.82H-134.472V-8.97C-134.472-13.714-132.884-17.777-129.751-21.046-126.716-24.267-123-25.901-118.707-25.901-115.85-25.901-113.278-25.154-110.845-23.621-108.566-22.221-106.707-20.248-105.319-17.758H-97.383-95.308-87.63-86.191-77.877-76.437-68.125-66.684-58.372-56.932-48.62-47.179-39.014-37.426-17.488-16.002 .197C6.782-17.758 11.382-13.055 11.382-6.322 11.382 .418 6.678 5.31 .197 5.31"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M570.349 150.806H564.625V145.054H570.349Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M579.707 150.806H573.98V145.054H579.707Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,636.6654,638.1387)"
            d="M0 0C.951 1.278 2.216 1.9 3.866 1.9 5.551 1.9 6.918 1.243 7.926-.048 8.789-1.241 9.227-2.713 9.227-4.422 9.227-6.141 8.806-7.58 7.979-8.696 7.065-9.892 5.781-10.499 4.161-10.499 1.026-10.499-1.249-7.861-1.249-4.226-1.249-2.563-.818-1.102 0 0M26.666-.743C26.666-2.862 26.458-3.801 26.287-4.207 25.77-5.238 24.672-5.74 22.93-5.74H20.123 18.097 15.199V-4.471C15.199-1.164 14.186 1.55 12.105 3.823 10.081 6.038 7.358 7.161 4.013 7.161 .718 7.161-2.019 6.072-4.126 3.924-6.18 1.779-7.221-.98-7.221-4.275-7.221-4.754-7.221-5.249-7.185-5.74H-10.663-12.491-15.893C-16.363 1.007-18.869 5.819-23.344 8.561-25.919 10.138-28.962 10.939-32.388 10.939-34.602 10.939-36.81 10.473-38.949 9.551-40.909 8.695-42.647 7.546-44.262 6.039L-44.357 5.952-44.286 5.844C-42.961 3.837-42.143 2.509-41.634 1.54L-41.532 1.343-41.383 1.509C-40.222 2.81-38.75 3.837-37.007 4.561-35.543 5.172-34.171 5.481-32.929 5.481-29.354 5.481-26.544 4.246-24.579 1.81-22.93-.254-22.08-2.793-22.052-5.74H-48.685-50.517-57.987C-57.295-4.711-56.958-2.894-56.958-.204V10.939H-62.733V-.498C-62.733-2.19-62.931-3.455-63.307-4.155-63.865-5.221-64.9-5.74-66.47-5.74H-69.57-71.744-78.041V10.939H-83.864V-7.954C-83.864-12.538-86.052-14.767-90.556-14.767H-90.71V-19.782H-90.556C-83.717-19.782-79.299-16.816-78.408-11.639H-71.744-69.57-50.517-48.685-12.491-10.663-4.879C-2.717-14.51 .194-15.907 4.013-15.907 7.844-15.907 10.869-14.471 13.004-11.639H18.097 20.123 24.063C29.655-11.639 32.49-8.6 32.49-2.606V20.308H26.666Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M673.922 118.222H679.697V150.218H673.922Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M716.587 155.025H710.862V149.224H716.587Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M707.279 155.025H701.553V149.224H707.279Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,705.4011,641.3285)"
            d="M0 0C-3 0-5.262 2.95-5.262 6.862-5.262 11.388-2.887 14.312 .789 14.312H5.609V7.352C5.609 3.023 3.302 0 0 0M113.575-1.134C113.575-1.615 113.575-2.07 113.537-2.55H91.907C95.208 4.377 100.907 9.014 106.145 9.014 110.728 9.014 113.575 5.125 113.575-1.134M131.952 2.447C131.952 .337 131.744-.607 131.573-1.017 131.056-2.048 129.957-2.55 128.214-2.55H125.112 123.873 119.544C119.357 8.582 115.014 14.227 106.636 14.227 101.406 14.227 96.707 11.551 92.281 6.05L92.957 23.498H87.227V-2.55H78.915 77.674 69.163 67.724 59.41 57.972 49.658 48.219 39.905 38.467 30.152 28.712 20.64 18.959 16.991C13.432-2.55 11.629-.622 11.629 3.183V18.886H-.689C-7.072 18.886-11.037 14.26-11.037 6.813-11.037-.283-6.975-4.868-.689-4.868 1.741-4.868 3.84-3.698 5.551-1.387 6.794-5.688 10.286-8.449 14.529-8.449H18.959 20.64 28.712 30.152 38.467 39.905 48.219 49.658 57.972 59.41 67.724 69.163 77.674 78.915 123.873 125.112 129.347C134.905-8.449 137.724-5.411 137.724 .583V14.128H131.952Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M837.401 107.479H843.077V113.28H837.401Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M847.993 118.222H853.76498V150.218H847.993Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,863.9506,627.2002)"
            d="M0 0V-18.892C0-23.476-2.189-25.705-6.691-25.705H-6.845V-30.721H-6.691C1.964-30.721 5.823-26.816 5.823-18.058V0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,162.609,683.67166)"
            d="M0 0C.361 .234 .782 .414 1.251 .535 2.398 .832 3.602 .803 4.697 .32 5.247 .078 5.676-.303 6.011-.842L6.774-2.071 11.078 1.659 10.291 2.583C9.293 3.755 8.133 4.594 6.841 5.074 4.644 5.892 1.845 6.011-.58 5.281-1.667 4.953-2.656 4.459-3.521 3.814-4.419 3.144-5.141 2.299-5.668 1.301-6.203 .287-6.474-.896-6.474-2.218-6.474-3.35-6.29-4.354-5.926-5.201-5.563-6.045-5.055-6.782-4.417-7.392-3.806-7.974-3.08-8.471-2.256-8.869-1.496-9.236-.657-9.565 .212-9.837L2.935-10.764C3.611-10.994 4.168-11.25 4.591-11.525 4.923-11.74 5.165-11.985 5.308-12.251 5.452-12.52 5.528-12.932 5.528-13.444 5.528-13.882 5.434-14.248 5.243-14.561 5.018-14.928 4.738-15.227 4.388-15.472 4.013-15.736 3.582-15.938 3.106-16.076 1.757-16.468 .458-16.324-.714-15.753-1.407-15.417-1.932-14.936-2.318-14.28L-3.048-13.046-7.42-16.611-6.8-17.536C-6.323-18.249-5.743-18.859-5.078-19.348-4.445-19.816-3.756-20.201-3.026-20.495-2.315-20.779-1.561-20.992-.783-21.128-.017-21.261 .742-21.328 1.473-21.328 2.641-21.328 3.792-21.174 4.894-20.87 6.033-20.555 7.058-20.065 7.94-19.416 8.847-18.747 9.587-17.893 10.139-16.873 10.699-15.834 10.983-14.613 10.983-13.247 10.983-11.911 10.742-10.761 10.268-9.827 9.801-8.908 9.161-8.13 8.364-7.509 7.619-6.931 6.755-6.449 5.794-6.077 4.927-5.74 4.013-5.419 3.066-5.118 2.193-4.851 1.447-4.579 .847-4.312 .319-4.076-.09-3.832-.374-3.581-.607-3.378-.766-3.165-.861-2.933-.966-2.676-1.019-2.357-1.019-1.986-1.019-1.512-.93-1.124-.748-.801-.557-.466-.311-.202 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,185.8937,693.4745)"
            d="M0 0H11.205V5.036H0V9.935H12.003V14.972H-5.255V-10.929H12.6V-5.892H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,222.9282,697.1507)"
            d="M0 0C-.725-.913-1.577-1.619-2.53-2.1-3.472-2.573-4.588-2.812-5.85-2.812-6.993-2.812-8.043-2.594-8.971-2.164-9.914-1.728-10.731-1.131-11.401-.387-12.079 .365-12.621 1.27-13.009 2.301-13.397 3.34-13.595 4.481-13.595 5.697-13.595 6.825-13.397 7.922-13.009 8.96-12.621 9.993-12.072 10.911-11.378 11.688-10.694 12.457-9.867 13.078-8.919 13.535-7.186 14.373-5.03 14.486-3.104 13.606-2.246 13.216-1.543 12.642-.952 11.852L-.226 10.881 4.204 14.086 3.442 15.085C2.312 16.561 .886 17.643-.796 18.299-4.175 19.614-8.085 19.528-11.166 18.179-12.773 17.475-14.18 16.502-15.348 15.289-16.52 14.074-17.442 12.624-18.085 10.977-18.725 9.339-19.049 7.563-19.049 5.697-19.049 3.754-18.711 1.939-18.046 .304-17.381-1.337-16.443-2.781-15.257-3.988-14.072-5.191-12.664-6.145-11.071-6.824-9.469-7.504-7.713-7.849-5.85-7.849-3.802-7.849-1.928-7.479-.279-6.75 1.406-6.003 2.861-4.826 4.047-3.251L4.744-2.323 .786 .992Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,248.3967,693.8375)"
            d="M0 0C0-.854-.09-1.689-.268-2.479-.433-3.208-.707-3.858-1.085-4.413-1.437-4.93-1.904-5.336-2.512-5.653-3.117-5.966-3.905-6.126-4.853-6.126-5.821-6.126-6.612-5.968-7.203-5.655-7.804-5.34-8.267-4.934-8.621-4.41-8.996-3.861-9.272-3.21-9.436-2.479-9.615-1.689-9.706-.855-9.706 0V15.335H-14.962V-.398C-14.962-1.785-14.784-3.122-14.434-4.368-14.071-5.672-13.48-6.838-12.681-7.835-11.865-8.854-10.792-9.675-9.49-10.271-8.202-10.863-6.642-11.163-4.853-11.163-3.064-11.163-1.503-10.863-.215-10.271 1.087-9.674 2.161-8.854 2.976-7.835 3.774-6.838 4.364-5.672 4.73-4.368 5.078-3.117 5.255-1.781 5.255-.398V15.335H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,273.8532,687.6172)"
            d="M0 0C-.315-.359-.711-.618-1.209-.791-1.791-.994-2.453-1.116-3.178-1.154-3.997-1.197-4.774-1.218-5.506-1.218H-6.769V4.077H-4.077C-3.449 4.077-2.814 4.037-2.192 3.956-1.658 3.886-1.178 3.743-.761 3.531-.411 3.354-.136 3.108 .078 2.78 .275 2.477 .376 2.023 .376 1.429 .376 .767 .25 .286 0 0M3.941-3.596C5.196-2.303 5.832-.612 5.832 1.429 5.832 3.119 5.486 4.507 4.802 5.559 4.129 6.59 3.24 7.386 2.16 7.924 1.153 8.427 .005 8.757-1.257 8.907-2.424 9.045-3.608 9.114-4.775 9.114H-12.024V-16.786H-6.769V-6.255H-5.105L1.279-16.786H7.604L.66-5.566C1.981-5.14 3.08-4.481 3.941-3.596"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M287.031 63.597H292.28703V89.498H287.031Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,298.0025,683.5398)"
            d="M0 0H7.78V-20.864H13.035V0H20.816V5.037H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,332.7293,688.0304)"
            d="M0 0-5.948 9.527H-12.468L-2.643-5.412V-16.373H2.613V-5.411L12.437 9.527H6.127Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,374.9371,686.18826)"
            d="M0 0C0-1.218-.458-1.688-.803-1.936-1.459-2.408-2.363-2.647-3.49-2.647H-6.98V2.648H-3.49C-2.363 2.648-1.459 2.409-.802 1.936-.458 1.689 0 1.219 0 0M2.388 6.111C1.5 6.689 .476 7.103-.655 7.343-1.723 7.57-2.856 7.685-4.021 7.685H-12.235V-18.215H-6.98V-7.684H-3.49C-2.584-7.684-1.623-7.574-.631-7.357 .416-7.128 1.395-6.727 2.275-6.168 3.206-5.577 3.974-4.767 4.558-3.761 5.154-2.737 5.456-1.46 5.456 .034 5.456 1.449 5.176 2.678 4.623 3.686 4.071 4.691 3.319 5.508 2.388 6.111"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,399.3306,687.6172)"
            d="M0 0C-.315-.358-.711-.617-1.209-.791-1.791-.994-2.453-1.116-3.178-1.154-3.997-1.197-4.774-1.218-5.506-1.218H-6.769V4.077H-4.078C-3.45 4.077-2.815 4.037-2.193 3.956-1.659 3.886-1.178 3.743-.762 3.532-.411 3.354-.136 3.107 .078 2.78 .275 2.477 .376 2.023 .376 1.429 .376 .768 .249 .286 0 0M3.94-3.596C5.195-2.305 5.831-.614 5.831 1.429 5.831 3.12 5.485 4.51 4.802 5.56 4.127 6.59 3.238 7.386 2.159 7.925 1.152 8.427 .004 8.757-1.257 8.907-2.426 9.045-3.609 9.114-4.775 9.114H-12.024V-16.786H-6.769V-6.255H-5.105L1.278-16.786H7.604L.659-5.566C1.981-5.14 3.08-4.481 3.94-3.596"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,432.7551,691.4538)"
            d="M0 0C0-1.188-.202-2.317-.599-3.353-.995-4.382-1.555-5.291-2.266-6.055-2.967-6.811-3.822-7.416-4.805-7.854-6.763-8.723-9.394-8.725-11.35-7.854-12.333-7.417-13.188-6.811-13.889-6.055-14.6-5.291-15.16-4.382-15.556-3.352-15.953-2.319-16.155-1.192-16.155 0-16.155 1.192-15.953 2.32-15.556 3.353-15.159 4.383-14.599 5.292-13.889 6.056-13.186 6.813-12.332 7.418-11.351 7.855-10.372 8.29-9.272 8.511-8.079 8.511-6.885 8.511-5.784 8.29-4.807 7.855-3.823 7.418-2.968 6.812-2.266 6.056-1.556 5.292-.996 4.383-.599 3.354-.202 2.318 0 1.189 0 0M1.621 9.694C.414 10.895-1.029 11.848-2.67 12.526-4.309 13.202-6.128 13.546-8.079 13.546-10.028 13.546-11.847 13.202-13.486 12.526-15.127 11.848-16.57 10.895-17.776 9.694-18.987 8.487-19.939 7.034-20.607 5.376-21.273 3.726-21.611 1.918-21.611 0-21.611-1.918-21.273-3.726-20.608-5.373-19.94-7.031-18.988-8.484-17.776-9.693-16.569-10.895-15.126-11.849-13.486-12.526-11.844-13.202-10.024-13.546-8.079-13.546-6.132-13.546-4.312-13.202-2.67-12.526-1.032-11.849 .412-10.895 1.621-9.693 2.831-8.487 3.783-7.034 4.452-5.375 5.117-3.725 5.455-1.917 5.455 0 5.455 1.917 5.118 3.724 4.452 5.376 3.782 7.036 2.83 8.489 1.621 9.694"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,445.1243,704.4035)"
            d="M0 0H5.255V10.531H16.227V15.568H5.255V20.864H17.058V25.901H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,473.7519,693.4745)"
            d="M0 0H11.205V5.036H0V9.935H12.004V14.972H-5.255V-10.929H12.601V-5.892H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,498.4448,683.67166)"
            d="M0 0C.361 .235 .782 .414 1.249 .535 2.403 .834 3.603 .803 4.697 .32 5.245 .078 5.676-.303 6.013-.843L6.775-2.07 11.078 1.659 10.291 2.584C9.292 3.755 8.132 4.594 6.841 5.074 4.647 5.89 1.843 6.012-.581 5.281-1.67 4.953-2.66 4.458-3.521 3.815-4.417 3.147-5.14 2.301-5.67 1.3-6.204 .286-6.474-.897-6.474-2.218-6.474-3.353-6.29-4.355-5.927-5.203-5.562-6.049-5.053-6.786-4.417-7.391-3.808-7.974-3.082-8.471-2.257-8.869-1.498-9.235-.659-9.563 .212-9.837L2.935-10.764C3.61-10.994 4.166-11.25 4.59-11.525 4.923-11.741 5.164-11.985 5.308-12.253 5.451-12.518 5.527-12.93 5.527-13.444 5.527-13.883 5.434-14.249 5.243-14.561 5.018-14.928 4.738-15.226 4.387-15.472 4.012-15.736 3.581-15.938 3.107-16.076 1.752-16.466 .459-16.324-.714-15.753-1.407-15.417-1.932-14.934-2.321-14.279L-3.051-13.047-7.42-16.611-6.8-17.536C-6.324-18.248-5.745-18.857-5.079-19.348-4.445-19.817-3.756-20.202-3.027-20.495-2.318-20.779-1.564-20.992-.784-21.128-.018-21.261 .741-21.328 1.472-21.328 2.64-21.328 3.79-21.174 4.894-20.87 6.031-20.555 7.055-20.066 7.94-19.416 8.848-18.746 9.587-17.892 10.137-16.874 10.698-15.838 10.982-14.617 10.982-13.247 10.982-11.91 10.741-10.759 10.267-9.826 9.798-8.906 9.159-8.127 8.365-7.509 7.62-6.932 6.756-6.45 5.794-6.077 4.927-5.74 4.013-5.419 3.066-5.118 2.191-4.85 1.443-4.578 .847-4.312 .319-4.076-.091-3.831-.374-3.583-.606-3.378-.766-3.165-.861-2.933-.967-2.674-1.02-2.355-1.02-1.986-1.02-1.513-.931-1.125-.747-.8-.557-.465-.313-.204 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,521.8184,683.67166)"
            d="M0 0C.361 .235 .782 .414 1.248 .535 2.399 .832 3.603 .803 4.696 .32 5.244 .078 5.675-.303 6.011-.843L6.774-2.069 11.079 1.66 10.29 2.584C9.29 3.756 8.128 4.595 6.84 5.074 4.645 5.89 1.845 6.012-.581 5.281-1.67 4.953-2.66 4.459-3.522 3.814-4.418 3.145-5.14 2.3-5.669 1.301-6.204 .287-6.476-.896-6.476-2.218-6.476-3.354-6.292-4.358-5.927-5.201-5.564-6.045-5.057-6.782-4.418-7.392-3.807-7.975-3.08-8.472-2.258-8.869-1.502-9.234-.663-9.562 .21-9.837L2.934-10.764C3.609-10.994 4.166-11.25 4.589-11.525 4.922-11.741 5.164-11.987 5.308-12.253 5.451-12.518 5.527-12.93 5.527-13.444 5.527-13.882 5.433-14.248 5.241-14.563 5.017-14.928 4.738-15.226 4.384-15.473 4.012-15.736 3.581-15.938 3.105-16.076 1.756-16.466 .458-16.324-.715-15.753-1.408-15.417-1.934-14.934-2.322-14.279L-3.052-13.047-7.42-16.611-6.8-17.536C-6.324-18.248-5.746-18.856-5.079-19.348-4.442-19.818-3.752-20.204-3.027-20.495-2.317-20.779-1.564-20.992-.784-21.128-.017-21.261 .742-21.328 1.472-21.328 2.638-21.328 3.789-21.174 4.892-20.87 6.028-20.556 7.054-20.066 7.939-19.416 8.849-18.745 9.589-17.889 10.135-16.875 10.697-15.839 10.982-14.618 10.982-13.247 10.982-11.91 10.741-10.759 10.266-9.825 9.795-8.903 9.155-8.125 8.362-7.509 7.618-6.931 6.753-6.449 5.793-6.077 4.921-5.738 4.006-5.417 3.065-5.12 2.19-4.85 1.442-4.578 .845-4.312 .318-4.076-.091-3.832-.374-3.583-.607-3.377-.767-3.165-.861-2.933-.967-2.671-1.021-2.353-1.021-1.986-1.021-1.513-.932-1.125-.747-.8-.557-.465-.313-.204 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M539.846 63.597H545.102V89.498H539.846Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,573.6261,691.4538)"
            d="M0 0C0-1.189-.202-2.317-.6-3.353-.996-4.382-1.556-5.291-2.267-6.055-2.968-6.811-3.823-7.417-4.805-7.854-6.763-8.725-9.394-8.723-11.35-7.854-12.334-7.416-13.189-6.811-13.891-6.054-14.601-5.29-15.162-4.382-15.556-3.352-15.954-2.317-16.156-1.189-16.156 0-16.156 1.191-15.954 2.319-15.556 3.354-15.16 4.383-14.6 5.292-13.889 6.056-13.188 6.812-12.333 7.418-11.348 7.855-10.372 8.29-9.272 8.511-8.077 8.511-6.885 8.511-5.784 8.29-4.807 7.855-3.824 7.418-2.97 6.813-2.267 6.056-1.557 5.292-.997 4.383-.6 3.353-.202 2.319 0 1.191 0 0M1.62 9.694C.414 10.895-1.029 11.849-2.668 12.526-4.307 13.202-6.127 13.546-8.077 13.546-10.028 13.546-11.847 13.202-13.486 12.526-15.124 11.849-16.567 10.897-17.776 9.694-18.987 8.487-19.939 7.035-20.608 5.375-21.274 3.724-21.611 1.917-21.611 0-21.611-1.917-21.273-3.725-20.608-5.373-19.94-7.032-18.987-8.485-17.776-9.693-16.566-10.897-15.123-11.849-13.486-12.526-11.844-13.202-10.024-13.546-8.077-13.546-6.131-13.546-4.311-13.202-2.668-12.526-1.031-11.849 .412-10.895 1.62-9.693 2.831-8.485 3.784-7.032 4.452-5.375 5.118-3.723 5.456-1.914 5.456 0 5.456 1.914 5.118 3.723 4.452 5.376 3.783 7.035 2.831 8.487 1.62 9.694"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,603.2169,695.3958)"
            d="M0 0-11.826 16.893H-17.223V-9.008H-11.968V7.884L-.142-9.008H5.255V16.893H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,629.722,693.6051)"
            d="M0 0H-5.93L-2.892 7.162ZM-4.898 15.102-16.356-10.798H-10.491L-8.064-5.036H2.064L4.457-10.798H10.401L-.654 15.102Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,650.2986,678.5029)"
            d="M0 0H-5.256V-25.901H10.772V-20.864H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,671.8973,683.67166)"
            d="M0 0C.361 .234 .782 .414 1.251 .535 2.398 .832 3.6 .803 4.697 .32 5.247 .078 5.676-.303 6.011-.842L6.774-2.071 11.078 1.659 10.291 2.583C9.293 3.755 8.133 4.594 6.841 5.074 4.644 5.892 1.844 6.011-.58 5.281-1.667 4.953-2.656 4.459-3.521 3.814-4.419 3.144-5.141 2.299-5.668 1.301-6.203 .287-6.474-.896-6.474-2.218-6.474-3.35-6.29-4.354-5.926-5.201-5.563-6.045-5.055-6.782-4.417-7.392-3.806-7.974-3.08-8.471-2.256-8.869-1.496-9.236-.657-9.565 .212-9.837L2.935-10.764C3.611-10.994 4.168-11.25 4.591-11.525 4.923-11.74 5.165-11.985 5.308-12.251 5.452-12.52 5.528-12.932 5.528-13.444 5.528-13.882 5.434-14.248 5.243-14.561 5.018-14.928 4.738-15.227 4.388-15.472 4.013-15.736 3.582-15.938 3.106-16.076 1.757-16.468 .458-16.324-.714-15.753-1.407-15.417-1.932-14.936-2.318-14.28L-3.048-13.046-7.42-16.611-6.8-17.536C-6.323-18.249-5.743-18.859-5.078-19.348-4.445-19.816-3.755-20.201-3.026-20.495-2.315-20.779-1.561-20.992-.783-21.128-.017-21.261 .742-21.328 1.473-21.328 2.641-21.328 3.792-21.174 4.894-20.87 6.033-20.555 7.058-20.065 7.94-19.416 8.847-18.747 9.587-17.893 10.139-16.873 10.699-15.834 10.983-14.613 10.983-13.247 10.983-11.911 10.742-10.761 10.268-9.827 9.801-8.908 9.161-8.13 8.364-7.509 7.619-6.931 6.755-6.449 5.794-6.077 4.927-5.74 4.013-5.419 3.066-5.118 2.193-4.851 1.447-4.579 .847-4.312 .319-4.076-.09-3.832-.374-3.581-.607-3.378-.766-3.165-.861-2.933-.966-2.676-1.019-2.357-1.019-1.986-1.019-1.512-.93-1.124-.748-.801-.557-.466-.311-.202 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,708.8031,683.67166)"
            d="M0 0C.361 .234 .78 .414 1.249 .535 2.394 .832 3.598 .804 4.697 .32 5.245 .078 5.675-.303 6.01-.842L6.773-2.07 11.079 1.659 10.291 2.584C9.291 3.756 8.13 4.595 6.84 5.074 4.643 5.891 1.841 6.009-.578 5.281-1.667 4.954-2.658 4.461-3.523 3.814-4.421 3.143-5.142 2.297-5.668 1.302-6.204 .29-6.476-.895-6.476-2.218-6.476-3.352-6.29-4.356-5.927-5.2-5.567-6.041-5.059-6.778-4.417-7.393-3.805-7.976-3.077-8.473-2.257-8.869-1.5-9.235-.66-9.563 .21-9.837L2.935-10.764C3.611-10.994 4.168-11.25 4.59-11.525 4.922-11.74 5.164-11.987 5.308-12.251 5.452-12.52 5.528-12.932 5.528-13.444 5.528-13.88 5.434-14.247 5.241-14.563 5.017-14.928 4.738-15.226 4.386-15.473 4.013-15.736 3.582-15.938 3.105-16.076 1.762-16.47 .459-16.324-.716-15.753-1.408-15.417-1.934-14.936-2.32-14.28L-3.05-13.046-7.42-16.611-6.8-17.536C-6.324-18.248-5.745-18.857-5.078-19.348-4.442-19.818-3.752-20.204-3.026-20.495-2.315-20.78-1.561-20.992-.783-21.128-.016-21.261 .743-21.328 1.473-21.328 2.64-21.328 3.79-21.174 4.892-20.87 6.031-20.555 7.057-20.066 7.939-19.416 8.848-18.746 9.588-17.891 10.137-16.874 10.698-15.835 10.983-14.615 10.983-13.247 10.983-11.911 10.742-10.761 10.267-9.826 9.798-8.906 9.157-8.126 8.362-7.509 7.617-6.931 6.751-6.448 5.793-6.077 4.921-5.738 4.006-5.417 3.065-5.12 2.192-4.85 1.445-4.579 .845-4.311 .319-4.076-.09-3.832-.374-3.581-.607-3.377-.767-3.165-.861-2.933-.967-2.674-1.02-2.355-1.02-1.986-1.02-1.512-.931-1.124-.748-.801-.557-.466-.311-.202 0 0"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,739.0012,682.9433)"
            d="M0 0C1.194 0 2.294-.22 3.272-.655 4.255-1.092 5.11-1.699 5.812-2.454 6.521-3.219 7.082-4.128 7.479-5.158 7.875-6.19 8.077-7.319 8.077-8.511 8.077-9.702 7.875-10.83 7.479-11.863 7.083-12.892 6.523-13.801 5.812-14.566 5.111-15.321 4.257-15.926 3.273-16.365 1.316-17.234-1.318-17.234-3.271-16.365-4.256-15.926-5.111-15.321-5.812-14.566-6.523-13.801-7.083-12.892-7.479-11.862-7.875-10.83-8.077-9.702-8.077-8.511-8.077-7.319-7.875-6.19-7.479-5.158-7.082-4.128-6.521-3.219-5.812-2.454-5.11-1.699-4.255-1.092-3.271-.655-2.294-.22-1.194 0 0 0M9.698 1.183C8.492 2.385 7.049 3.337 5.409 4.015 3.77 4.692 1.951 5.036 0 5.036-1.949 5.036-3.769 4.692-5.408 4.015-7.048 3.337-8.491 2.385-9.698 1.183-10.909-.024-11.862-1.477-12.529-3.135-13.194-4.783-13.532-6.592-13.532-8.511-13.532-10.431-13.194-12.238-12.53-13.884-11.863-15.542-10.911-16.995-9.698-18.203-8.49-19.406-7.046-20.359-5.408-21.036-3.765-21.713-1.946-22.057 0-22.057 1.947-22.057 3.766-21.713 5.409-21.036 7.048-20.359 8.491-19.406 9.698-18.203 10.911-16.995 11.863-15.542 12.529-13.885 13.195-12.237 13.533-10.428 13.533-8.511 13.533-6.593 13.195-4.784 12.529-3.136 11.862-1.477 10.909-.024 9.698 1.183"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,777.1348,697.1495)"
            d="M0 0C-.728-.915-1.579-1.622-2.533-2.101-3.472-2.574-4.588-2.814-5.85-2.814-6.994-2.814-8.044-2.596-8.971-2.166-9.914-1.73-10.731-1.132-11.401-.388-12.08 .364-12.621 1.268-13.009 2.301-13.399 3.337-13.596 4.48-13.596 5.696-13.596 6.824-13.399 7.922-13.009 8.959-12.619 9.993-12.072 10.912-11.378 11.687-10.694 12.456-9.867 13.077-8.919 13.534-7.202 14.363-5.019 14.478-3.104 13.605-2.246 13.214-1.543 12.641-.952 11.851L-.226 10.88 4.205 14.086 3.44 15.084C2.31 16.561 .885 17.642-.797 18.298-4.176 19.612-8.083 19.526-11.167 18.178-12.772 17.475-14.179 16.502-15.35 15.288-16.52 14.073-17.442 12.623-18.086 10.976-18.726 9.338-19.051 7.562-19.051 5.696-19.051 3.756-18.713 1.942-18.047 .302-17.38-1.339-16.442-2.783-15.257-3.989-14.071-5.193-12.663-6.147-11.071-6.825-9.471-7.506-7.714-7.851-5.85-7.851-3.802-7.851-1.928-7.48-.279-6.751 1.405-6.006 2.858-4.828 4.045-3.252L4.744-2.325 .788 .989Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,0,768)"
            d="M787.741 63.597H792.99606V89.498H787.741Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,806.5296,693.4745)"
            d="M0 0H11.204V5.036H0V9.935H12.001V14.972H-5.256V-10.929H12.6V-5.892H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,823.317,683.5398)"
            d="M0 0H7.78V-20.864H13.036V0H20.816V5.037H0Z"
            fill="#ffffff"
          />
          <path
            transform="matrix(1,0,0,-1,864.1707,678.5029)"
            d="M0 0-6.127-9.527-12.075 0H-18.596L-8.77-14.939V-25.901H-3.515V-14.939L6.31 0Z"
            fill="#ffffff"
          />
        </g>
      </g>
    </svg>
  );
};

export default FooterLogoIcon;
