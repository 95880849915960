import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../app/store';
import { uploadUsersCsvWithNoMembershipId } from '../../../app/api';
import { CSVDownload } from "react-csv";
import styles from './MembershipIdGenerator.module.scss'

const MembershipIdUpload = () => {
  const csvRef: any = useRef(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [downloadCsv, setDownloadCsv] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const { allUsersForCsv } = useSelector(
    (state: RootState) => state.user
  );

  const handleUploadFile = async () => {
    const formData: any = new FormData()

    if (selectedFile !== null) {
      formData.append("usersCsv", selectedFile);
      await dispatch(uploadUsersCsvWithNoMembershipId(formData))
    }
  }

  const handleChange = async (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file)
  }

  return (
    <div className={styles.files}>
      {
        downloadCsv && <CSVDownload ref={csvRef} data={allUsersForCsv} target="_blank" />
      }
      <p>Export Users CSV with no MembershipId:</p>
      <div>
        <div className={styles.fileUpload}>
          <div className={styles.fileInput}>
            <input
              type="file"
              onChange={handleChange}
              accept=".csv"
            />
          </div>
          <button onClick={handleUploadFile}>Import CSV</button>
        </div>
      </div>
    </div>
  );
};

export default MembershipIdUpload;