import React, { useEffect, useState } from "react";
import { Slide } from "../../../../@types/slide";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import Table from "../../../components/Table/table";
import { deleteSlide, getSLideList } from "../apis/adminApi";
import SlideForm from "../SlideForm/slideForm";
import { toast } from "react-toastify";
import { REACT_APP_AMAZON_S3_URL } from "../../../../constants/constant";
import { setdDisabledBtn } from "../../../../app/features/disabledBtnSlice";
import { useDispatch } from "react-redux";
import { ListParams, Meta } from "../../../../@types/table";
import Loading from "../../../../components/Loading/Loading";

const SlideList = () => {
  const tableHeader: string[] = [
    "id",
    "title_en",
    "title_ar",
    "subtitle_en",
    "subtitle_ar",
    "active_from",
    "active_to",
  ];

  const [slideData, setSlideData] = useState<Slide[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<Slide | null>(null);
  const [isLodaing, setIsLoading] = useState<boolean>(false);
  const [metaData, setMetaData] = useState<Meta>();
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const onCreateSlide = () => {
    setEditItem(null);
    setIsEdit(false);
    setShowModal(true);
  };

  const dispatch = useDispatch();

  const onHandelPageClick = (page: number): void => {
    console.log(page);
    getListSlideData({ page: page, take: 10 });
  };

  const onHandelClose = (showType: boolean, addedItem: Slide) => {
    if (addedItem && !isEdit) {
      const updatedData = [...slideData];
      updatedData.push({
        ...addedItem,
        image_url: `${REACT_APP_AMAZON_S3_URL}/${addedItem?.image_url}`,
      });
      setSlideData(updatedData);
    }

    if (addedItem && isEdit) {
      const indexItem = slideData.findIndex(
        (item) => item?.id === addedItem?.id
      );

      if (indexItem !== -1) {
        const updatedSlideData = [...slideData];
        updatedSlideData[indexItem] = {
          ...addedItem,
          image_url: `${REACT_APP_AMAZON_S3_URL}/${addedItem?.image_url}`,
        };
        setSlideData(updatedSlideData);
      }
    }
    setShowModal(showType);
  };

  const onEditItem = (type: string, item: Slide) => {
    setEditItem(item);
    if (type === "edit") {
      setIsEdit(true);
      setShowModal(true);
    } else {
      setShowConfirmationModal(true);
    }
  };

  const handleConfirmClick = () => {
    dispatch(setdDisabledBtn(true));
    deleteSlide(editItem?.id!).then((res) => {
      toast.success("deleted successfully !");
      setShowConfirmationModal(false);
      let updatedSlidData = [...slideData];
      updatedSlidData = updatedSlidData.filter(
        (item) => item.id !== editItem?.id
      );
      setSlideData(updatedSlidData);
      dispatch(setdDisabledBtn(false));
    });
  };

  useEffect(() => {
    getListSlideData({ page: 1, take: 10 });
  }, []);

  const getListSlideData = (params: ListParams) => {
    setIsLoading(true);
    getSLideList(params).then((res) => {
      const data = res?.data?.map((item: Slide) => ({
        ...item,
        image_url: `${REACT_APP_AMAZON_S3_URL}/${item?.image_url}`,
      }));
      setSlideData(data);
      setMetaData(res?.meta);
      setIsLoading(false);
    });
  };

  return isLodaing ? (
    <Loading />
  ) : (
    <>
      <Table
        tableHeader={
          slideData?.length ? Object.keys(slideData[0] ?? []) : tableHeader
        }
        tableBody={slideData}
        createRecord={onCreateSlide}
        getEditItem={onEditItem}
        metaData={metaData!}
        handlePageClick={onHandelPageClick}
      />

      {showModal && (
        <SlideForm
          isEdit={isEdit}
          showModal={showModal}
          editRowData={editItem!}
          closeModal={(type: boolean, addedItem?: Slide) =>
            onHandelClose(type, addedItem!)
          }
        />
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          text="Are you sure you want to continue ?"
          onClose={() => setShowConfirmationModal(false)}
          onClick={handleConfirmClick}
          onOutside
        />
      )}
    </>
  );
};

export default SlideList;
