import React from "react";
import styles from "./SwitchLanguage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage as setReduxLanguage } from "../../app/features/languageSlice";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";
import { setBreadCrumbsArray } from "../../app/features/breadcrumbSlice";

const SwitchLanguage: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { currentLanguage } = useSelector((state: RootState) => state.language);

  const handleChangeLanguage = () => {
    if (currentLanguage.value === "en") {
      dispatch(setReduxLanguage({ value: "ar", label: "العربية" }));
      i18n.changeLanguage("ar");
    } else {
      dispatch(setReduxLanguage({ value: "en", label: "Eng" }));
      i18n.changeLanguage("en");
    }

    const tabs: any[] = t("media_page.tab", { returnObjects: true });

    if (localStorage?.breadcrumbs) {
      let breadcrumbs = JSON.parse(localStorage.breadcrumbs);

      if (breadcrumbs && breadcrumbs.length) {
        breadcrumbs.forEach((breadcrumb: any) => {
          if (breadcrumb.key === "news" || breadcrumb.key === "event") {
            breadcrumb.name = tabs.find(
              (tab) => tab.value === breadcrumb.key
            ).label;
          } else {
            breadcrumb.name = t(breadcrumb.key);
          }
        });
      }

      dispatch(setBreadCrumbsArray(breadcrumbs));
    }
  };

  return (
    <div onClick={handleChangeLanguage} className={styles.language}>
      {currentLanguage.value === "en" ? "العربية" : "Eng"}
    </div>
  );
};

export default SwitchLanguage;
