import React from "react";
import styles from "./Hero.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { TSlideProps } from "../../@types/type";
import { v4 as uuidv4 } from "uuid";
import HeroContent from "./Content/HeroContent";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const HeroSection = () => {
  const { slideData } = useSelector((state: RootState) => state.main);

  return (
    <div className={`container ${styles.heroSection}`}>
      <Swiper
        direction="vertical"
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {slideData?.map((item: TSlideProps) => {
          return (
            <SwiperSlide key={uuidv4()}>
              <HeroContent {...item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HeroSection;
