import React from "react";
import styles from "./InputField.module.scss";
import { useTranslation } from "react-i18next";

type TInputFieldProps = {
  id: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  labelIcon?: React.ReactNode;
  inputIcon?: React.ReactNode;
  required?: boolean;
  maxLength?: number;
  forAdmin?: boolean;
  handleInputIconClick?: () => void;
};

const InputField = ({
  id,
  value,
  onChange,
  onKeyPress,
  label,
  type,
  placeholder,
  labelIcon,
  inputIcon,
  required,
  maxLength,
  forAdmin,
  handleInputIconClick,
}: TInputFieldProps) => {
  const { i18n } = useTranslation();
  const defaultClass =
    i18n.language === "ar" ? "arabicContent" : "englishContent";

  return (
    <div className={styles.inputFieldWrapper}>
      {label ? (
        <label htmlFor={id}>
          {labelIcon ? (
            <span className={styles.labelIcon}>{labelIcon}</span>
          ) : null}
          {label}
          {required ? <span className={styles.required}>&nbsp;*</span> : null}
        </label>
      ) : null}
      <div
        className={`${styles.inputContainer} ${
          forAdmin ? styles.adminInput : ""
        } ${defaultClass}`}
      >
        <input
          id={id}
          type={type}
          onKeyDown={onKeyPress}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          autoComplete="off"
          maxLength={maxLength}
        />
        {inputIcon ? (
          <span className={styles.inputIcon} onClick={handleInputIconClick}>
            {inputIcon}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default InputField;
