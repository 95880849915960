import React, { Dispatch, useEffect, useState } from "react";
import styles from "../JoinUs.module.scss";
import InputField from "../../../components/InputField/InputField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import { fDayTime, fYearTime } from "../../../utils/formats";
import { TPersonalDetailsFormProps } from "../../../@types/type";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalDetails } from "../../../app/features/joinUsSlice";
import { RootState } from "../../../app/store";

type TPersonalDetailsProps = {
  setIsCardsValid: Dispatch<React.SetStateAction<boolean>>;
};

const PersonalDetailsCard: React.FC<TPersonalDetailsProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setIsCardsValid } = props;
  const { personalDetails } = useSelector((state: RootState) => state.joinUs);

  const monthTranslations: string[] = t("date.months", { returnObjects: true });
  const countries = t("countries", { returnObjects: true });
  const nationalities = t("nationalities", { returnObjects: true });
  const gender = t("gender", { returnObjects: true });

  const months = monthTranslations.map((month, index) => {
    const value = (index + 1 < 10 ? "0" : "") + (index + 1).toString();
    return { value, label: month };
  });

  const nationalityOptions = Object.entries(nationalities).map(
    ([value, label]) => ({
      value,
      label,
    })
  );
  const countryOptions = Object.entries(countries)?.map(([index, value]) => {
    return { value: value.code, label: value.name };
  });

  const genderOptions = Object.entries(gender).map(([value, label]) => ({
    value,
    label,
  }));

  const [personalErrors, setPersonalErrors] = useState<
    Partial<TPersonalDetailsFormProps>
  >({
    date: "",
    gender: "",
    nationality: "",
    residency: "",
  });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (id === "day") {
      const modifiedValue = value ? fDayTime(value) : value;
      dispatch(setPersonalDetails({ key: id, value: modifiedValue }));
    } else {
      if (value.length <= 4) {
        const yearValue = fYearTime(value);
        dispatch(setPersonalDetails({ key: id, value: yearValue }));
      }
    }
  };

  useEffect(() => {
    const hasAnyError = Object.values(personalErrors).some((value) => !value);
    const isFormEmpty = Object.values(personalDetails).every((value) => value);

    const isFormValid = isFormEmpty && hasAnyError;

    setIsCardsValid(isFormValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetails, personalErrors]);

  useEffect(() => {
    const day = personalDetails.dateValue?.day || "";
    const year = personalDetails.dateValue?.year || "";
    const month = personalDetails.selectedMonth?.value || "";

    // Validate date
    if (day !== "") {
      const dateRegex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;
      const isValidDate = dateRegex.test(`${month}/${day}/${year}`);

      setPersonalErrors((prevErrors) => ({
        ...prevErrors,
        date: isValidDate ? "" : t("join_us.errors.date_text"),
      }));
    }
  }, [personalDetails.selectedMonth, personalDetails.dateValue, t]);

  return (
    <div className={styles.personalDetails}>
      <p className={styles.sectionTitle}>
        {t("join_us.personal_details_text")}
      </p>
      <div className={styles.sectionContent}>
        <div className={`${styles.inputField} ${styles.fullWidth}`}>
          <p className={styles.dateTitle}>
            {t("join_us.date_Of_Birth.label_text")}{" "}
            <span className={styles.required}>&nbsp;*</span>
          </p>
          <div className={styles.birthDate}>
            <InputField
              id="day"
              value={personalDetails.dateValue?.day}
              onChange={handleDateChange}
              type="number"
              required
              placeholder={t("join_us.date_Of_Birth.day_text")}
            />
            <Dropdown
              defaultValue={t("join_us.date_Of_Birth.month_text")}
              options={months}
              selectedValue={
                personalDetails.selectedMonth
                  ? personalDetails.selectedMonth.label
                  : ""
              }
              onValueChange={(selectedValue) =>
                dispatch(
                  setPersonalDetails({
                    key: "selectedMonth",
                    value: selectedValue,
                  })
                )
              }
            />
            <InputField
              id="year"
              value={personalDetails.dateValue?.year}
              onChange={handleDateChange}
              type="number"
              required
              placeholder={t("join_us.date_Of_Birth.year_text")}
            />
            {personalErrors?.date && (
              <div className={styles.error}>{personalErrors?.date}</div>
            )}
          </div>
        </div>
        <div className={`${styles.inputField}`}>
          <div className={styles.checkboxTitle}>
            <span>{t("join_us.gender.title_text")}</span>
            <span className={styles.required}>*</span>
          </div>
          <div>
            <Dropdown
              defaultValue={t("join_us.gender.placeholder_text")}
              options={genderOptions}
              inputPlaceholder={t("join_us.nationality.placeholder_text")}
              selectedValue={
                personalDetails.gender ? personalDetails.gender : ""
              }
              onValueChange={(selectedValue) => {
                dispatch(
                  setPersonalDetails({
                    key: "gender",
                    value: selectedValue.value,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className={styles.inputField}>
          <p className={styles.dateTitle}>
            {t("join_us.nationality.label_text")}{" "}
            <span className={styles.required}>&nbsp;*</span>
          </p>
          <div>
            <Dropdown
              defaultValue={t("join_us.nationality.placeholder_text")}
              options={nationalityOptions}
              search
              inputPlaceholder={t("join_us.nationality.placeholder_text")}
              selectedValue={
                personalDetails.selectedNationality
                  ? personalDetails.selectedNationality.label
                  : ""
              }
              onValueChange={(selectedValue) =>
                dispatch(
                  setPersonalDetails({
                    key: "selectedNationality",
                    value: selectedValue,
                  })
                )
              }
            />
          </div>
        </div>
        <div className={styles.inputField}>
          <p className={styles.dateTitle}>
            {t("join_us.residency.label_text")}{" "}
            <span className={styles.required}>&nbsp;*</span>
          </p>
          <div>
            <Dropdown
              defaultValue={t("join_us.residency.placeholder_text")}
              search
              inputPlaceholder={t("join_us.residency.placeholder_text")}
              options={countryOptions}
              selectedValue={
                personalDetails.selectedResidency
                  ? personalDetails.selectedResidency.label
                  : ""
              }
              onValueChange={(selectedValue) => {
                dispatch(
                  setPersonalDetails({
                    key: "selectedResidency",
                    value: selectedValue,
                  })
                );
                setPersonalErrors({
                  ...personalErrors,
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsCard;
